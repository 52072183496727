import React from 'react'
import "./ServiceCenterLocatorPageContent.css"
const StoreLocatorContentCard = () => {
  return (
    <>
      <section className='service-center-locator-content-section-card'>
        <div className='container'>
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="store-locator-card">
                  <h4>A K Auto Electricals</h4>
                  <div className="row">
                    <div className="col-md-10">
                      <div className="para-store-add">
                        <p>Shop No. 00, Ram Ji Lal Mkt, Opp. L Pocket, S / South-South</p>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="phone-icon">
                        <a href="#"><img src="./images/store-locator/phone.png" alt="" /></a>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default StoreLocatorContentCard