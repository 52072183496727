import React from 'react'
import Navbar from '../Header/Navbar'
import Banner from '../Carousel/Banner'
import NavbarSecond from '../Header/NavbarSecond'
import DesignSolarPowerLoadCal from '../HomePageComponent/DesignSolarPowerLoadCal/DesignSolarPowerLoadCal'
import KnowMoreAboutPower from '../HomePageComponent/KnowMoreAboutPower/KnowMoreAboutPower'
import WhyEapro from '../HomePageComponent/WhyEapro/WhyEapro'
import OurSpecialization from '../HomePageComponent/OurSpecialization/OurSpecialization'
import PillarsOfEAPRO from '../HomePageComponent/PillarsOfEAPRO/PillarsOfEAPRO'
import KnowledgeCentreSlider from '../HomePageComponent/KnowledgeCentreSlider/KnowledgeCentreSlider'
import OurProductSection from '../HomePageComponent/OurProductSection/OurProductSection'
import HelpAndSupport from '../HomePageComponent/HelpAndSupport/HelpAndSupport'
import Footer from '../Footer/Footer'
import MobileNavbar from '../Header/MobileNavbar'

const Home = () => {
  return (
    <>
      <Navbar />
      <NavbarSecond/>
      <MobileNavbar/>
      <Banner />
      <DesignSolarPowerLoadCal/>
      <KnowMoreAboutPower/>
      <WhyEapro/>
      <OurSpecialization/>
      <PillarsOfEAPRO/>
      <KnowledgeCentreSlider/>
      <OurProductSection/>
      <HelpAndSupport/>
      <Footer/>
    </>
  )
}

export default Home