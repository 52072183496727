import React, { useEffect } from 'react'
import "./CareerAtEaproOneContent.css"
const CareerAtEaproOneContent = () => {
  useEffect(() => {
    var isAdvancedUpload = function () {
      var div = document.createElement('div');
      return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
    }();

    let draggableFileArea = document.querySelector(".drag-file-area");
    let browseFileText = document.querySelector(".browse-files");
    let uploadIcon = document.querySelector(".upload-icon");
    let dragDropText = document.querySelector(".dynamic-message");
    let fileInput = document.querySelector(".default-file-input");
    let cannotUploadMessage = document.querySelector(".cannot-upload-message");
    let cancelAlertButton = document.querySelector(".cancel-alert-button");
    let uploadedFile = document.querySelector(".file-block");
    let fileName = document.querySelector(".file-name");
    let fileSize = document.querySelector(".file-size");
    let progressBar = document.querySelector(".progress-bar");
    let removeFileButton = document.querySelector(".remove-file-icon");
    let uploadButton = document.querySelector(".upload-button");
    let fileFlag = 0;

    fileInput.addEventListener("click", () => {
      fileInput.value = '';
      console.log(fileInput.value);
    });

    fileInput.addEventListener("change", e => {
      console.log(" > " + fileInput.value)
      uploadIcon.innerHTML = 'check_circle';
      dragDropText.innerHTML = 'File Dropped Successfully!';
      document.querySelector(".label").innerHTML = `drag & drop or <span class="browse-files"> <input type="file" class="default-file-input" style=""/> <span class="browse-files-text" style="top: 0;"> browse file</span></span>`;
      uploadButton.innerHTML = `Upload`;
      fileName.innerHTML = fileInput.files[0].name;
      fileSize.innerHTML = (fileInput.files[0].size / 1024).toFixed(1) + " KB";
      uploadedFile.style.cssText = "display: flex;";
      progressBar.style.width = 0;
      fileFlag = 0;
    });

    uploadButton.addEventListener("click", () => {
      let isFileUploaded = fileInput.value;
      if (isFileUploaded != '') {
        if (fileFlag == 0) {
          fileFlag = 1;
          var width = 0;
          var id = setInterval(frame, 50);
          function frame() {
            if (width >= 900) {
              clearInterval(id);
              uploadButton.innerHTML = `<span class="material-icons-outlined upload-button-icon"> check_circle </span> Uploaded`;
            } else {
              width += 5;
              progressBar.style.width = width + "px";
            }
          }
        }
      } else {
        cannotUploadMessage.style.cssText = "display: flex; animation: fadeIn linear 1.5s;";
      }
    });

    cancelAlertButton.addEventListener("click", () => {
      cannotUploadMessage.style.cssText = "display: none;";
    });

    if (isAdvancedUpload) {
      ["drag", "dragstart", "dragend", "dragover", "dragenter", "dragleave", "drop"].forEach(evt =>
        draggableFileArea.addEventListener(evt, e => {
          e.preventDefault();
          e.stopPropagation();
        })
      );

      ["dragover", "dragenter"].forEach(evt => {
        draggableFileArea.addEventListener(evt, e => {
          e.preventDefault();
          e.stopPropagation();
          uploadIcon.innerHTML = 'file_download';
          dragDropText.innerHTML = 'Drop your file here!';
        });
      });

      draggableFileArea.addEventListener("drop", e => {
        uploadIcon.innerHTML = 'check_circle';
        dragDropText.innerHTML = 'File Dropped Successfully!';
        document.querySelector(".label").innerHTML = `drag & drop or <span class="browse-files"> <input type="file" class="default-file-input" style=""/> <span class="browse-files-text" style="top: -23px; left: -20px;"> browse file</span> </span>`;
        uploadButton.innerHTML = `Upload`;

        let files = e.dataTransfer.files;
        fileInput.files = files;
        console.log(files[0].name + " " + files[0].size);
        console.log(document.querySelector(".default-file-input").value);
        fileName.innerHTML = files[0].name;
        fileSize.innerHTML = (files[0].size / 1024).toFixed(1) + " KB";
        uploadedFile.style.cssText = "display: flex;";
        progressBar.style.width = 0;
        fileFlag = 0;
      });
    }

    removeFileButton.addEventListener("click", () => {
      uploadedFile.style.cssText = "display: none;";
      fileInput.value = '';
      uploadIcon.innerHTML = 'file_upload';
      dragDropText.innerHTML = 'Drag & drop any file here';
      document.querySelector(".label").innerHTML = `or <span class="browse-files"> <input type="file" class="default-file-input"/> <span class="browse-files-text">browse file</span> <span>from device</span> </span>`;
      uploadButton.innerHTML = `Upload`;
    });

  });
  useEffect(() => {
    var loadFile = function (event) {
      var reader = new FileReader();
      reader.onload = function () {
        var output = document.getElementById('output-cus');
        output.src = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    };
  });
  return (
    <>
      <section className='career-at-eapro-search-jobs'>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <form action="">
                <div className="row">
                  <div className="col-md-6 text-start">
                    <label htmlFor="search-by-keyword-id" className='form-label'>Search by Keyword</label>
                    <input type="text" className="form-control" placeholder='' id='search-by-keyword-id' />
                  </div>
                  <div className="col-md-6 text-start">
                    <label htmlFor="search-by-location-id" className='form-label'>Search by Location</label>
                    <input type="text" className="form-control" placeholder='' id='search-by-location-id' />
                  </div>
                  <div className="col-md-12">
                    <div className="search-job-btn">
                      <a href="" className=''>Search Jobs</a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className='career-at-eapro-page-content-section'>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="career-content-heading">
                <h5>Application Form</h5>
              </div>
            </div>
          </div>
          <div className="form-parent-custon-dv">
            <form className="row g-3 custom-row-form form-container">
              <div className="col-md-12">
                <label htmlFor="validationServer01" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationServer01"
                  defaultValue=""
                  required=""
                  placeholder='First Name'
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="email-id" className="form-label">
                  Email ID
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email-id"
                  defaultValue=""
                  required=""
                  placeholder='Email ID'
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="total-experience" className="form-label">
                  Total Experience
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="total-experience"
                  defaultValue=""
                  required=""
                  placeholder='Total Experience'
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="contact-no" className="form-label">
                  Contact No
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="contact-no"
                  defaultValue=""
                  required=""
                  placeholder='Mobile Number'
                />
              </div>
              <div className="col-md-6 mt-5">
                <input
                  type="text"
                  className="form-control"
                  defaultValue=""
                  required=""
                  placeholder='Alternative Number'
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="current-year-salary" className="form-label">
                  Current Yearly salary
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="current-year-salary"
                  defaultValue=""
                  required=""
                  placeholder='Current Yearly salary'
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="fixed" className="form-label">
                  Fixed
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fixed"
                  defaultValue=""
                  required=""
                  placeholder='Fixed'
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="variables" className="form-label">
                  Variables
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="variables"
                  defaultValue=""
                  required=""
                  placeholder='Variables'
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="expected-yearly-salary" className="form-label">
                  Expected Yearly Salary
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="expected-yearly-salary"
                  defaultValue=""
                  required=""
                  placeholder='Expected Yearly Salary'
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="notice-period" className="form-label">
                  Notice Period
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="notice-period"
                  defaultValue=""
                  required=""
                  placeholder='Notice Period'
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="preferred-location" className="form-label">
                  Preferred Location
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="preferred-location"
                  defaultValue=""
                  required=""
                  placeholder='Preferred Location'
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="core-strength" className="form-label">
                  Core Strength
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="core-strength"
                  defaultValue=""
                  required=""
                  placeholder='Core Strength'
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="address-input" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  required=""
                  placeholder='City'
                />
              </div>
              <div className="col-md-3 mt-5">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  required=""
                  placeholder='State'
                />
              </div>
              <div className="col-md-3 mt-5">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  required=""
                  placeholder='Postal Code'
                />
              </div>

              <div className="col-md-3 mt-5">
                <select
                  className="form-select"
                  id="validationServerstate"
                  aria-describedby=""
                  required=""
                  placeholder="Country"
                >
                  <option selected="" disabled="" value="">
                    Country
                  </option>
                  <option>...</option>
                </select>
              </div>

              {/* <div className="col-md-12">
                <label htmlFor="what-position" className="form-label">
                  What position are you applying for?
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="what-position"
                  defaultValue=""
                  required=""
                  placeholder='Description'
                />
              </div> */}
              <div className="col-md-12">
                <label htmlFor="referred-by" className="form-label">
                  Referred By/Source
                </label>
                <select
                  className="form-select"
                  id="referred-by"
                  aria-describedby=""
                  required=""
                  placeholder=""
                >
                  <option selected="" disabled="" value="">
                    Country
                  </option>
                  <option>...</option>
                </select>
              </div>
              {/* <div className="col-md-12">
                <label htmlFor="what-is-your" className="form-label">
                  What is your current employment status?
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="what-is-your"
                  defaultValue=""
                  required=""
                  placeholder='Description'
                />
              </div> */}
              {/* <div className="col-md-6">
                <label htmlFor="current-ctc" className="form-label">
                  Current CTC
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="current-ctc"
                  defaultValue=""
                  required=""
                  placeholder='Description'
                />
              </div> */}
              {/* <div className="col-md-6">
                <label htmlFor="expected-ctc" className="form-label">
                  Expected CTC
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="expected-ctc"
                  defaultValue=""
                  required=""
                  placeholder='Description'
                />
              </div> */}
              <div className="col-md-12">
                <label htmlFor="" className="form-label">
                  Upload Your Resume
                </label>
                <div className="upload-files-container">
                  <div className="drag-file-area">
                    <span className="material-icons-outlined upload-icon"> <img src="./images/career/clound.png" alt="" /></span>
                    <h3 className="dynamic-message"> Drag &amp; drop any file here </h3>
                    <label className="label">

                      <span className="browse-files">
                        <input type="file" className="default-file-input" hidden />
                        <span className="browse-files-text">Browse file</span>
                        <span> from device</span>
                      </span>
                    </label>
                  </div>
                  <span className="cannot-upload-message">

                    <span className="material-icons-outlined">error</span> Please select a file
                    first
                    <span className="material-icons-outlined cancel-alert-button">cancel</span>
                  </span>
                  <div className="file-block">
                    <div className="file-info">

                      <span className="material-icons-outlined file-icon">
                        description
                      </span>
                      <span className="file-name"> </span> | <span className="file-size"></span>
                    </div>
                    <span className="material-icons remove-file-icon">delete</span>
                    <div className="progress-bar"> </div>
                  </div>
                  <button type="button" className="upload-button">

                    Upload
                  </button>
                </div>

              </div>
              <div className="col-md-12 text-center">
                <img id="output-cus" width={"100px"} height={"100px"} />
              </div>
              <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3">
                <label className="file-label-cus upload-photograph-parent" htmlFor='file-id-cus'>
                  <span>Upload Your Photograph</span>
                  <input type="file" accept="image/*" id="file-id-cus" onChange={"loadFile(event)"} placeholder='Upload Your Photograph' hidden />
                  <div className="icon-img">
                    <img src="./images/career/upload-icon.png" alt="" />
                  </div>
                </label>
              </div>
              <div className="col-12 text-center">
                <button className="btn submit-btn-custom" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>

        </div>
      </section>
    </>
  )
}

export default CareerAtEaproOneContent