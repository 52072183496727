import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import RegisterYourComplaintBanner from './RegisterYourComplaintBanner/RegisterYourComplaintBanner'
import RegisterYourComplaintContent from './RegisterYourComplaintContent/RegisterYourComplaintContent'

const RegisterYourComplaintPage = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <RegisterYourComplaintBanner/>
            <RegisterYourComplaintContent/>
            <Footer />
        </>
    )
}

export default RegisterYourComplaintPage