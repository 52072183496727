import React from 'react'
import './EventPageContent.css'
import { Link } from 'react-router-dom'
const EventPageContentCard = () => {
    return (
        <>
            <div className="event-card-parent">
                <div className="row">
                    <div className="col-md-4">
                        <div className="card">
                            <img src="./images/blog/card-img.png" className="card-img-top" alt="..." />
                            <Link to="/news-event-details">
                                <div className="card-body">
                                    <h5>Lorem Ipsum</h5>
                                    <p className="card-text">
                                        Yoga is an ancient practice that originated in India and encompasses physical, mental, and spiritual aspects.
                                    </p>
                                    <p><i>June 21, 2023</i></p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <img src="./images/blog/card-img.png" className="card-img-top" alt="..." />
                            <Link to="/news-event-details">
                                <div className="card-body">
                                    <h5>Lorem Ipsum</h5>
                                    <p className="card-text">
                                        Yoga is an ancient practice that originated in India and encompasses physical, mental, and spiritual aspects.
                                    </p>
                                    <p><i>June 21, 2023</i></p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card">
                            <img src="./images/blog/card-img.png" className="card-img-top" alt="..." />
                            <Link to="/news-event-details">
                                <div className="card-body">
                                    <h5>Lorem Ipsum</h5>
                                    <p className="card-text">
                                        Yoga is an ancient practice that originated in India and encompasses physical, mental, and spiritual aspects.
                                    </p>
                                    <p><i>June 21, 2023</i></p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mt-5">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination justify-content-center">
                                <li class="page-item disabled">
                                    <a class="page-link" href="#" tabindex="-1" aria-disabled="true">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={11}
                                            height={19}
                                            viewBox="0 0 11 19"
                                            fill="none"
                                        >
                                            <mask
                                                id="mask0_177_474"
                                                style={{ maskType: "luminance" }}
                                                maskUnits="userSpaceOnUse"
                                                x={0}
                                                y={0}
                                                width={11}
                                                height={19}
                                            >
                                                <path
                                                    d="M10.2793 0L10.2793 18.5867L-0.000340308 18.5867L-0.000339508 -4.56107e-07L10.2793 0Z"
                                                    fill="white"
                                                />
                                            </mask>
                                            <g mask="url(#mask0_177_474)">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M9.87793 0.467912C9.97163 0.561412 10.0461 0.672914 10.0969 0.795919C10.1477 0.918925 10.1738 1.05097 10.1738 1.18434C10.1738 1.31772 10.1477 1.44976 10.0969 1.57277C10.0461 1.69577 9.97163 1.80728 9.87793 1.90077L2.52817 9.29361L9.87792 16.6872C9.97163 16.7807 10.0461 16.8922 10.0969 17.0152C10.1477 17.1382 10.1738 17.2703 10.1738 17.4037C10.1738 17.537 10.1477 17.6691 10.0969 17.7921C10.0461 17.9151 9.97163 18.0266 9.87792 18.1201C9.68841 18.3102 9.43272 18.4169 9.16626 18.4169C8.89981 18.4169 8.64407 18.3102 8.45456 18.1201L0.391942 10.0108C0.298234 9.91735 0.223811 9.80584 0.173006 9.68284C0.122201 9.55983 0.0960384 9.42779 0.0960384 9.29441C0.0960384 9.16104 0.122201 9.029 0.173006 8.90599C0.223811 8.78298 0.298234 8.67146 0.391942 8.57796L8.45456 0.468714C8.64407 0.278674 8.89968 0.172024 9.16607 0.171875C9.43247 0.171726 9.68821 0.278085 9.87793 0.467912Z"
                                                    fill="black"
                                                />
                                            </g>
                                        </svg>

                                    </a>
                                </li>
                                <li class="page-item"><a class="page-link" href="#">1</a></li>
                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item">
                                    <a class="page-link" href="#">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={11}
                                            height={19}
                                            viewBox="0 0 11 19"
                                            fill="none"
                                        >
                                            <mask
                                                id="mask0_177_479"
                                                style={{ maskType: "luminance" }}
                                                maskUnits="userSpaceOnUse"
                                                x={0}
                                                y={0}
                                                width={11}
                                                height={19}
                                            >
                                                <path
                                                    d="M10.9922 0L10.9922 18.5867L0.71255 18.5867L0.712551 -4.56107e-07L10.9922 0Z"
                                                    fill="white"
                                                />
                                            </mask>
                                            <g mask="url(#mask0_177_479)">
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M1.1045 0.467912C1.01079 0.561412 0.936366 0.672914 0.885561 0.795919C0.834756 0.918924 0.808594 1.05097 0.808594 1.18434C0.808594 1.31772 0.834756 1.44976 0.885561 1.57277C0.936366 1.69577 1.01079 1.80728 1.1045 1.90077L8.45425 9.29361L1.1045 16.6872C1.01079 16.7807 0.936367 16.8922 0.885562 17.0152C0.834757 17.1382 0.808594 17.2703 0.808594 17.4037C0.808594 17.537 0.834757 17.6691 0.885562 17.7921C0.936367 17.9151 1.01079 18.0266 1.1045 18.1201C1.29401 18.3102 1.5497 18.4169 1.81616 18.4169C2.08261 18.4169 2.33835 18.3102 2.52787 18.1201L10.5905 10.0108C10.6842 9.91735 10.7586 9.80584 10.8094 9.68284C10.8602 9.55983 10.8864 9.42779 10.8864 9.29441C10.8864 9.16104 10.8602 9.02899 10.8094 8.90599C10.7586 8.78298 10.6842 8.67146 10.5905 8.57796L2.52787 0.468714C2.33835 0.278674 2.08274 0.172024 1.81635 0.171875C1.54996 0.171726 1.29421 0.278085 1.1045 0.467912Z"
                                                    fill="black"
                                                />
                                            </g>
                                        </svg>

                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventPageContentCard