import React from 'react'
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
import "./KnowMoreAboutPower.css";
import "./ResponsiveKnowMoreAboutPower.css";
const JavascriptVoid = (event) => {
    event.preventDefault();
};

const Slider_group = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    lazyLoad: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    initialSlide: 0,
    arrows: true,
    swipe: true,
    centerMode: true,
    centerPadding: '10px',
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 0
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
const KnowMoreAboutPower = () => {
    return (
        <>
            <section className='know-more-about-power-section'>
                <div className="container">
                    <div className="heading-dv">
                        <h5>Know More About Power and <br />Solar Power Systems</h5>
                    </div>
                    <Slider {...Slider_group} className='slider-parent-dv'>
                        <div className='slider-item-dv'>
                            <div className="slider-item-img">
                                <img src="../images/on-grid-inverter.png" alt="" className='img-fluid' />
                            </div>
                            <div className="slider-item-heading">
                                <h4>On Grid Inverter</h4>
                            </div>
                        </div>
                        <div className='slider-item-dv'>
                            <div className="slider-item-img">
                                <img src="../images/off-grid-hybrid-inverter.png" alt="" className='img-fluid' />
                            </div>
                            <div className="slider-item-heading">
                                <h4>Off Grid Hybrid Inverter</h4>
                            </div>
                        </div>
                        <div className='slider-item-dv'>
                            <div className="slider-item-img">
                                <img src="../images/off-grid-inverter.png" alt="" className='img-fluid' />
                            </div>
                            <div className="slider-item-heading">
                                <h4>Off Grid Inverter</h4>
                            </div>
                        </div>
                        <div className='slider-item-dv'>
                            <div className="slider-item-img">
                                <img src="../images/on-grid-inverter.png" alt="" className='img-fluid' />
                            </div>
                            <div className="slider-item-heading">
                                <h4>On Grid Inverter</h4>
                            </div>
                        </div>
                        <div className='slider-item-dv'>
                            <div className="slider-item-img">
                                <img src="../images/off-grid-hybrid-inverter.png" alt="" className='img-fluid' />
                            </div>
                            <div className="slider-item-heading">
                                <h4>Off Grid Hybrid Inverter</h4>
                            </div>
                        </div>
                        <div className='slider-item-dv'>
                            <div className="slider-item-img">
                                <img src="../images/off-grid-inverter.png" alt="" className='img-fluid' />
                            </div>
                            <div className="slider-item-heading">
                                <h4>Off Grid Inverter</h4>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
        </>
    )
}

export default KnowMoreAboutPower