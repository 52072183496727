import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import EaproGlobalLtdBanner from './EaproGlobalLtdBanner/EaproGlobalLtdBanner'
import EaproGlobalLtdContent from './EaproGlobalLtdContent/EaproGlobalLtdContent'
import EaproGlobalLtdForms from './EaproGlobalLtdContent/EaproGlobalLtdForms'

const EaproGlobalLtd = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <EaproGlobalLtdBanner />
            <EaproGlobalLtdContent />
            <EaproGlobalLtdForms/>
            <Footer />
        </>
    )
}

export default EaproGlobalLtd