import React from 'react'
import "./TRCEfficientServicesForms.css"
const TRCEfficientServicesForms = () => {
    return (
        <>
            <section className='trc-global-energy-enquiry-section'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 text-center">
                            <div className="enquiry-heading">
                                <h5>Apply For Service Franchise</h5>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="enquiry-left-d">
                                <img src="./images/global-energy/apply-for-service.png" alt="" className='img-fluid' />
                                <p className='mt-2'><b>Join us on this journey of service excellence. At TRC Efficient & Services Pvt Ltd, we are not just a service provider; we are a reliable partner committed to enhancing your operational efficiency. </b></p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="enquiry-form-right-dv">

                                <form action=''>
                                    <div className="mb-4 pb-2">
                                        <input type="text" className="form-control rounded-0" placeholder='Name*' />
                                    </div>
                                    <div className="mb-4 pb-2">
                                        <input type="email" className="form-control rounded-0" placeholder='Email*' />
                                    </div>
                                    <div className="mb-4 pb-2">
                                        <input type="text" className="form-control rounded-0" placeholder='Phone*' />
                                    </div>
                                    <div className="mb-4 pb-2">
                                        <textarea name="" id="" className="form-control rounded-0" rows="5" placeholder='Address'>

                                        </textarea>
                                    </div>
                                    <div className="mb-4 pb-2 text-start">
                                        <button type="submit" className="btn btn-primary enq-submit-btn">Submit</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TRCEfficientServicesForms