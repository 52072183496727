import React from 'react'
import './BlogContent.css'
import './ResponsiveBlogContent.css'
import BlogContentCard from './BlogContentCard'
const BlogContent = () => {
    return (
        <>
            <section className='blog-page-content-section'>
                <div className="container">
                    {/* ------------------------------------------------ */}
                    <ul class="nav nav-tabs blog-nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#solar-technology" type="button" role="tab" aria-controls="home" aria-selected="true">Solar Technology</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#power-technology" type="button" role="tab" aria-controls="profile" aria-selected="false">Power Technology</button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#general" type="button" role="tab" aria-controls="contact" aria-selected="false">General</button>
                        </li>
                    </ul>
                    <div class="tab-content blog-tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="solar-technology" role="tabpanel" aria-labelledby="home-tab">

                            <BlogContentCard />
                        </div>
                        <div class="tab-pane fade" id="power-technology" role="tabpanel" aria-labelledby="profile-tab">
                            <BlogContentCard />
                        </div>
                        <div class="tab-pane fade" id="general" role="tabpanel" aria-labelledby="contact-tab">
                            <BlogContentCard />
                        </div>
                    </div>
                    {/* ------------------------------------------------ */}

                </div>
            </section>
        </>
    )
}

export default BlogContent