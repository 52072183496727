import React from 'react'
import "./CareerPageBanner.css"
import "./ResponsiveCareerPageBanner.css"
const CareerPageBanner = () => {
  return (
    <>
      <section className='career-banner-section'>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 text-start">
              {/* <div className="business-heading text-start">
                <img src="./images/help-and-support/heading-bg.png" alt="" />
                <h1>Help & Support</h1>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CareerPageBanner