import React from 'react'
import EventPageBanner from './EventPageBanner/EventPageBanner'
import Navbar from '../Header/Navbar'
import MobileNavbar from '../Header/MobileNavbar'
import NavbarSecond from '../Header/NavbarSecond'
import Footer from '../Footer/Footer'
import EventPageContent from './EventPageContent/EventPageContent'

const EventPage = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <EventPageBanner />
            <EventPageContent/>
            <Footer />
        </>
    )
}

export default EventPage