import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import InstallationHelpPageBanner from './InstallationHelpPageBanner/InstallationHelpPageBanner'
import InstallationHelpPageContent from './InstallationHelpPageContent/InstallationHelpPageContent'

const InstallationHelpPage = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <InstallationHelpPageBanner/>
            <InstallationHelpPageContent/>
            <Footer />
        </>
    )
}

export default InstallationHelpPage