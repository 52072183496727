import React from 'react';
import "./Footer.css";
import "./ResponsiveFooter.css";
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <>
            <section className='footer-section'>
                <div className="footer-inner-dv">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4 mt-lg-0 mt-md-4">
                                <div className="footer-content-parent">
                                    <div className="footer-heading">
                                        <h3>About Us</h3>
                                    </div>
                                    <div className="footer-content">
                                        <ul>
                                            <li><Link to="/global-energy-ltd">Eapro Global Ltd.</Link></li>
                                            <li><Link to="/global-energy">EAPRO Global Energy Pvt. Ltd.</Link></li>
                                            <li><Link to="/eapro-overseas">EAPRO Overseas Pvt. Ltd.</Link></li>
                                            <li><Link to="/trc-efficient-services">TRC Efficient & Services Pvt. Ltd.</Link></li>
                                            <li><Link to="/efficient-mechanical-solar">Efficient Mechanical & Solar Pvt. Ltd.</Link></li>
                                            <li><Link to="/blog">Blogs</Link></li>
                                            {/* <li><a href="#">News & Event</a></li>
                                            <li><a href="#">Global presence</a></li>
                                            <li><a href="#">CSR</a></li>
                                            <li><a href="#">Ethics & Compliance</a></li>
                                            <li><a href="#">5 Pillars of EAPRO</a></li>
                                            <li><a href="#">Business divisions</a></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 mt-lg-0 mt-md-4">
                                <div className="footer-content-parent">
                                    <div className="footer-heading">
                                        <h3>Eapro care</h3>
                                    </div>
                                    <div className="footer-content">
                                        <ul>
                                            <li><Link to="/store-locator">Store Locator</Link></li>
                                            <li><Link to="/service-center-locator">Service Center Locator </Link></li>
                                            <li><a href="#">Product Registration </a></li>
                                            <li><Link to="/register-your-complaint">Book Your Complaint </Link></li>
                                            <li><Link to="/installation-help">Installation Help</Link></li>
                                            <li><Link to="/purchase-amc">Buy AMC</Link></li>
                                        </ul>
                                    </div>
                                    {/* <div className="footer-heading mt-5">
                                        <h3>Registered office</h3>
                                    </div>
                                    <div className="footer-content">
                                        <ul>
                                            <li><a href="#">Full Address of head office  </a></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 mt-lg-0 mt-md-4">
                                <div className="footer-content-parent">
                                    <div className="footer-heading">
                                        <h3>Our policies</h3>
                                    </div>
                                    <div className="footer-content">
                                        <ul>
                                            <li><a href="#">Distributors Policy </a></li>
                                            <li><a href="#">Dealers Policy </a></li>
                                            <li><a href="#">Export Policy  </a></li>
                                            <li><a href="#">OEM & ODM Policy  </a></li>
                                            <li><a href="#">Privacy Policy</a></li>
                                            <li><a href="#">Term & Condition</a></li>
                                        </ul>
                                    </div>
                                    {/* <div className="footer-heading mt-5">
                                        <h3>Head Office</h3>
                                    </div>
                                    <div className="footer-content">
                                        <ul>
                                            <li><a href="#">Full Address of head office  </a></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 mt-lg-0 mt-md-4">
                                <div className="footer-content-parent">
                                    <div className="footer-heading">
                                        <h3>Get in touch</h3>
                                    </div>
                                    <div className="footer-content">
                                        <ul>
                                            <li><Link to="/contact-us">Contact US  </Link></li>
                                            <li><a href="#">Export Queries </a></li>
                                            <li><a href="#">OEM / ODM Query </a></li>
                                            <li><a href="#"> Domestics Sales Query</a></li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 mt-lg-0 mt-md-4">
                                <div className="footer-content-parent">
                                    <div className="footer-heading">
                                        <h3>Subscribe to our Newsletter</h3>
                                    </div>
                                    <div className="footer-content">
                                        <form action="">
                                            <div className="form-input-subscribe">
                                                <input type="text" placeholder='Enter Your Email' />
                                                <div className="at-rt-dv">
                                                    <img src="./images/email.png" alt="" />
                                                </div>

                                            </div>
                                            <div className="subscribe-btn">
                                                <input type='button' className='subs-btn-int' value="Subscribe" />
                                            </div>
                                        </form>

                                        <div className="mail-strip">
                                            <p><a href="mailto:info@eapro.in"><img src="./images/mail.png" alt="" />info@eapro.in</a></p>
                                        </div>
                                        <div className="whatsapp-strip">
                                            <p><a href="https://api.whatsapp.com/send?phone=919068563156" target='_blank'><img src="./images/whatsapp.png" alt="" />+91-9068563156</a></p>
                                        </div>
                                    </div>
                                    {/* <div className="footer-heading mt-4">
                                        <h3>Follow Us</h3>
                                    </div>
                                    <div className="footer-content">
                                        <div className="follow-us-ul">
                                            <ul>
                                                <li>
                                                    <a href="#" target='_blank'>
                                                        <img src="./images/linkedin.png" alt="" className="img-fluid" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" target='_blank'>
                                                        <img src="./images/facebook.png" alt="" className="img-fluid" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" target='_blank'>
                                                        <img src="./images/instagram.png" alt="" className="img-fluid" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" target='_blank'>
                                                        <img src="./images/twitter.png" alt="" className="img-fluid" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" target='_blank'>
                                                        <img src="./images/youtube.png" alt="" className="img-fluid" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        {/* </div>
                        <div className="row align-items-start"> */}
                            <div className="col-lg-3 col-md-4">
                                <div className="footer-content-parent">
                                    {/* <div className="footer-heading">
                                        <h3>About Us</h3>
                                    </div> */}
                                    <div className="footer-content">
                                        <ul>
                                            {/* <li><a href="#">Eapro Global Ltd.</a></li>
                                            <li><a href="#">Eapro Global Energy</a></li>
                                            <li><a href="#">Eapro Overseas</a></li>
                                            <li><a href="#">TRC Efficient Services</a></li>
                                            <li><a href="#">Efficient Mechanical & Solar</a></li>
                                            <li><a href="#">Blogs</a></li> */}
                                            <li><Link to="/news-event">News & Event</Link></li>
                                            <li><a href="#">Global presence</a></li>
                                            <li><a href="#">CSR</a></li>
                                            <li><a href="#">Ethics & Compliance</a></li>
                                            <li><a href="#">5 Pillars of EAPRO</a></li>
                                            <li><Link to="/business-division">Business divisions</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <div className="footer-content-parent mt-5">
                                    <div className="footer-heading">
                                        <h3>Corporate Tower</h3>
                                    </div>
                                    <div className="footer-content">
                                        <ul>
                                            <li><Link to="/contact-us">Upper Ganga canal Besides, Asaf Nagar, Delhi Rd, Roorkee, Uttarakhand 247666 </Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <div className="footer-content-parent mt-5">

                                    <div className="footer-heading">
                                        <h3>Industrial Address</h3>
                                    </div>
                                    <div className="footer-content">
                                        <ul>
                                            <li><Link to="/contact-us">106-107,Salempur Rajputana Industrial Area, Roorkee, Uttarakhand 247666</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <div className="footer-content-parent mt-5">
                                    <div className="footer-heading ">
                                        <h3>Follow Us</h3>
                                    </div>
                                    <div className="footer-content">
                                        <div className="follow-us-ul">
                                            <ul>
                                                <li>
                                                    <a href="#" target='_blank'>
                                                        <img src="./images/linkedin.png" alt="" className="img-fluid" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" target='_blank'>
                                                        <img src="./images/facebook.png" alt="" className="img-fluid" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" target='_blank'>
                                                        <img src="./images/instagram.png" alt="" className="img-fluid" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" target='_blank'>
                                                        <img src="./images/twitter.png" alt="" className="img-fluid" />
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#" target='_blank'>
                                                        <img src="./images/youtube.png" alt="" className="img-fluid" />
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copy-right-div">
                                    <p>Copyright Eapro Global Limited 2023. All rights reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer