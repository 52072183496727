import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import ECataloguePageBanner from './ECataloguePageBanner/ECataloguePageBanner'
import ECataloguePageContent from './ECataloguePageContent/ECataloguePageContent'

const ECataloguePage = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <ECataloguePageBanner/>
            <ECataloguePageContent/>
            <Footer />
        </>
    )
}

export default ECataloguePage