import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import SolarSolutionPageBanner from './SolarSolutionPageBanner/SolarSolutionPageBanner'
import SolarSolutionPageDetails from './SolarSolutionPageDetails/SolarSolutionPageDetails'
import PowerSolutionHomeInverter from '../PowerSolutionPage/PowerSolutionHomeInverter/PowerSolutionHomeInverter'
import PowerSolutionBatteryPage from '../PowerSolutionPage/PowerSolutionBatteryPage/PowerSolutionBatteryPage'
import SolarSolutionSolarPanel from './SolarSolutionSolarPanel/SolarSolutionSolarPanel'
import PowerSolutionPageAccessories from '../PowerSolutionPage/PowerSolutionPageAccessories/PowerSolutionPageAccessories'

const SolarSolutionPage = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <SolarSolutionPageBanner />
            <SolarSolutionPageDetails />
            <PowerSolutionHomeInverter />
            <PowerSolutionBatteryPage />
            <SolarSolutionSolarPanel />
            <PowerSolutionPageAccessories />
            <Footer />
        </>
    )
}

export default SolarSolutionPage