import React from 'react'
import "./ServiceCenterLocatorPageBanner.css"
const ServiceCenterLocatorPageBanner = () => {
    return (
        <>
            <section className='service-center-locator-banner-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="store-heading">
                                <img src="./images/store-locator/heading-bg.png" alt="" />
                                <h5>Service Centre Locator</h5>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="banner-img-dv">
                                <img src="./images/store-locator/service-center-locator.png" alt="" className='img-fluid'/>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="banner-dv-search">
                                <form action="">
                                    <div className="banner-search-parent">
                                        <input type="text" placeholder='Enter Pincode Or City' />
                                        <button className='banner-search-btn'>Search</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ServiceCenterLocatorPageBanner