import React from 'react'
import "./PillarsOfEAPRO.css"
import "./ResponsivePillarsOfEAPRO.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
const Slider_group = {
    dots: false,
    nav: false,
    infinite: true,
    speed: 500,
    lazyLoad: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 0
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }
    ]
};
const PillarsOfEAPRO = () => {
    return (
        <>
            <section className='pillars-of-eapro-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="specialization-heading-dv">
                                <h5>5 Pillars of EAPRO</h5>
                            </div>
                        </div>
                        <div className="col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-12">
                            <Slider {...Slider_group} className=" piller-card-row">
                                {/* <div className="col-md-1 col-lg-1 col-12"></div> */}
                                <div className="piller-items">
                                    <div className="piller-card-parent">
                                        <div className="piller-img">
                                            <img src="../images/piller-1.png" alt="" className='img-fluid' />
                                        </div>
                                        <div className="piller-content">
                                            <h4>Industry Leading R & D</h4>
                                            <p>Our company stands at the forefront of innovation with industry-leading Research and Development efforts. We continually pioneer groundbreaking solutions, setting new standards and driving progress in our field.</p>
                                            <a href='' className='piller-read-more'>Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="piller-items">
                                    <div className="piller-card-parent">
                                        <div className="piller-img">
                                            <img src="../images/piller-2.png" alt="" className='img-fluid' />
                                        </div>
                                        <div className="piller-content">
                                            <h4>Advance Technology</h4>
                                            <p>EAPRO utilizes advanced technology, including AI, IoT, and blockchain, to drive progress and reshape industries. Our smart, sustainable solutions redefine the future landscape with efficiency and innovation.</p>
                                            <a href='' className='piller-read-more'>Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="piller-items">
                                    <div className="piller-card-parent">
                                        <div className="piller-img">
                                            <img src="../images/piller-3.png" alt="" className='img-fluid' />
                                        </div>
                                        <div className="piller-content">
                                            <h4>Manufacturing Power</h4>
                                            <p>EAPRO: Empowering Industries with Manufacturing Excellence. We are the driving force behind efficient, innovative, and powerful manufacturing solutions, shaping a dynamic future for businesses worldwide.</p>
                                            <a href='' className='piller-read-more'>Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="piller-items">
                                    <div className="piller-card-parent">
                                        <div className="piller-img">
                                            <img src="../images/piller-4.png" alt="" className='img-fluid' />
                                        </div>
                                        <div className="piller-content">
                                            <h4>Quality Assurance</h4>
                                            <p>EAPRO's Quality Department ensures excellence in every aspect. We uphold rigorous standards, fostering trust and delivering top-tier products and services to meet the highest industry benchmarks.</p>
                                            <a href='' className='piller-read-more'>Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="piller-items">
                                    <div className="piller-card-parent">
                                        <div className="piller-img">
                                            <img src="../images/piller-5.png" alt="" className='img-fluid' />
                                        </div>
                                        <div className="piller-content">
                                            <h4>After Sale Services</h4>
                                            <p>After-Sales Services prioritize customer satisfaction. Our expert team provides swift support and maintenance, guaranteeing clients maximize the value of our products long after their purchase.</p>
                                            <a href='' className='piller-read-more'>Read More</a>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-1 col-lg-1 col-12"></div> */}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PillarsOfEAPRO