import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import TRCEfficientServicesBanner from './TRCEfficientServicesBanner/TRCEfficientServicesBanner'
import TRCEfficientServicesContent from './TRCEfficientServicesContent/TRCEfficientServicesContent'
import TRCEfficientServicesForms from './TRCEfficientServicesContent/TRCEfficientServicesForms'

const TRCEfficientServices = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <TRCEfficientServicesBanner />
            <TRCEfficientServicesContent />
            <TRCEfficientServicesForms/>
            <Footer />
        </>
    )
}

export default TRCEfficientServices