import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import ServiceCenterLocatorPageBanner from './ServiceCenterLocatorPageBanner/ServiceCenterLocatorPageBanner'
import ServiceCenterLocatorPageContent from './ServiceCenterLocatorPageContent/ServiceCenterLocatorPageContent'

const ServiceCenterLocatorPagePage = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <ServiceCenterLocatorPageBanner />
            <ServiceCenterLocatorPageContent/>
            <Footer />
        </>
    )
}

export default ServiceCenterLocatorPagePage