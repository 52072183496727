import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import PurchaseAMCPage1Banner from './PurchaseAMCPage1Banner/PurchaseAMCPage1Banner'
import PurchaseAMCPage1Content from './PurchaseAMCPage1Content/PurchaseAMCPage1Content'

const PurchaseAMCPage1 = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <PurchaseAMCPage1Banner />
            <PurchaseAMCPage1Content />
            <Footer />
        </>
    )
}

export default PurchaseAMCPage1