import React from 'react'
import CareerAtEaproTwoBanner from './CareerAtEaproTwoBanner/CareerAtEaproTwoBanner'
import Navbar from '../../Header/Navbar'
import NavbarSecond from '../../Header/NavbarSecond'
import MobileNavbar from '../../Header/MobileNavbar'
import Footer from '../../Footer/Footer'
import CareerAtEaproTwoContent from './CareerAtEaproTwoContent/CareerAtEaproTwoContent'
const CareerAtEaproTwo = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <CareerAtEaproTwoBanner />
            <CareerAtEaproTwoContent/>
            <Footer />
        </>
    )
}

export default CareerAtEaproTwo