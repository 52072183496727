import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import EAPROOverseasBanner from './EAPROOverseasBanner/EAPROOverseasBanner'
import EAPROOverseasContent from './EAPROOverseasContent/EAPROOverseasContent'
import EAPROOverseasForms from './EAPROOverseasContent/EAPROOverseasForms'

const EAPROOverseas = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <EAPROOverseasBanner />
            <EAPROOverseasContent />
            <EAPROOverseasForms/>
            <Footer />
        </>
    )
}

export default EAPROOverseas