import React from 'react'
import "./FounderMDSection.css"
const FounderMDSection = () => {
    return (
        <>
            <section className='founder-md-section-parent'>
                <div className='container'>
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <div className="founder-images-left-dv">
                                <img src="./images/about-us/jagdeep-chauhan.png" alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="founder-right-content">
                                <div className="founder-name">
                                    <h4>Jagdeep Chauhan</h4>
                                    <p>(Founder & M.D.)</p>
                                    <h6>His Belief — <span>“The Technology WINS The RACE"</span></h6>
                                </div>
                                <div className="founder-content-p">
                                    <p>Mr. Chauhan Is A Young And Energetic Electronic Graduate Engineer Who Invented The DSP Sine-Wave Inverter Technology In 2002 Which Made INDIA Become A WORLD LEADER In Production Of Power Backup Inverters.
                                    </p>
                                    <p>With Over 25 Years Of Experience (He Owns 9 Patents) In Power Electronics Development And Business Management, He Is Well Utilizing His Spiritual And Intellectual Energy To Enhance The Team’s Motivational Levels Which Run The Company Effectively.
                                    </p>
                                    <p>His Mission Is To Bring Futuristic, ECO- Friendly Energy Automation Products To The People At An Affordable Cost. Today, EAPRO Is One Of The Most Innovative Organizations Globally In Production Of Energy Automation Products & Solar Power Generating Systems.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="founder-right-content">
                                <div className="founder-name">
                                    <h4>Abhilasha Singh</h4>
                                    <p>(Director)</p>
                                    {/* <h6>His Belief — <span>“The Technology WINS The RACE"</span></h6> */}
                                </div>
                                <div className="founder-content-p mt-4">
                                    <p>Mrs. Abhilasha Singh Is Having Masters In Legislative Law (LLM). She Strongly Believes That
                                        People & Process Is The Main Constituent For Maintaining Product Quality. Consistent Effort In
                                        People Building Apart From Implementing Quality Processes And Management Processes In Each And Every Department.
                                    </p>
                                    <h6>With Over 22 Year's Of Experience.</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="founder-images-right-dv">
                                <img src="./images/about-us/abhilasha-singh.png" alt="" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FounderMDSection