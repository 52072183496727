import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import ContactUsPageBanner from './ContactUsPageBanner/ContactUsPageBanner'
import ContactUsPageContent from './ContactUsPageContent/ContactUsPageContent'

const ContactUsPage = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <ContactUsPageBanner />
            <ContactUsPageContent />
            <Footer />
        </>
    )
}

export default ContactUsPage