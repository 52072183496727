import React from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
import "./KnowledgeCentrePageContent.css"
import $ from 'jquery';
const JavascriptVoid = (event) => {
    event.preventDefault();
};
function pauseVideo() {
    console.log("trying to pause")
    let videoId = document.getElementById("iframeyt");
    videoId.contentWindow.postMessage('{"event":"command", "func":"stopVideo", "args":""}', '*')
}
const Slider_group = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    lazyLoad: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    initialSlide: 0,
    arrows: true,
    swipe: true,
    centerMode: true,
    centerPadding: '10px',
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 0
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
const KnowledgeCentrePageVideoTuto = () => {
    return (
        <>
            <section className='knowledge-center-page-video-tuto-slider-section'>
                <div className="container">
                    <div className="heading-dv">
                        <h5>Video Tutorials</h5>
                    </div>
                    <div className="heading-tabs-parent">
                        {/* <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#technical-video" type="button" role="tab" aria-controls="home" aria-selected="true">Product video</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link blogs-btn" id="profile-tab" data-bs-toggle="tab" data-bs-target="#blogs-tabs" type="button" role="tab" aria-controls="profile" aria-selected="false">Blogs</button>
                            </li>
                        </ul> */}
                        {/* <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="technical-video" role="tabpanel" aria-labelledby="home-tab">

                                <div className="footer-view-all-btn">
                                    <a href="#">View All</a>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="blogs-tabs" role="tabpanel" aria-labelledby="profile-tab">
                                ...........
                            </div>
                        </div> */}
                        <Slider {...Slider_group} className='slider-parent-dv'>
                            <div className='slider-item-dv'>
                                <div className="slider-item-img">
                                    <img src="../images/kc-1.png" alt="" className='img-fluid' />
                                    <div className="play-btn-youtube">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                            <img src="../images/youtube-play-btn.png" alt="" className='img-fluid ' />
                                        </a>
                                    </div>
                                </div>
                                <div className="slider-item-heading">
                                    <h4>SPGS Installation (5 KW/48V) MPPT</h4>
                                    <p>SPGS Installation (5 KW/48V) by EAPRO Global Ltd.</p>
                                    <p>Know more: www.eapro.in</p>
                                    <p>Call: <a href="tel:9068563156">9068563156</a></p>
                                    <p>Customer care: <a href="tel:8650115000">8650115000</a></p>
                                </div>
                            </div>
                            <div className='slider-item-dv'>
                                <div className="slider-item-img">
                                    <img src="../images/kc-1.png" alt="" className='img-fluid' />
                                    <div className="play-btn-youtube">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                            <img src="../images/youtube-play-btn.png" alt="" className='img-fluid ' />
                                        </a>
                                    </div>
                                </div>
                                <div className="slider-item-heading">
                                    <h4>SPGS Installation (5 KW/48V) MPPT</h4>
                                    <p>SPGS Installation (5 KW/48V) by EAPRO Global Ltd.</p>
                                    <p>Know more: www.eapro.in</p>
                                    <p>Call: <a href="tel:9068563156">9068563156</a></p>
                                    <p>Customer care: <a href="tel:8650115000">8650115000</a></p>
                                </div>
                            </div>
                            <div className='slider-item-dv'>
                                <div className="slider-item-img">
                                    <img src="../images/kc-1.png" alt="" className='img-fluid' />
                                    <div className="play-btn-youtube">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                            <img src="../images/youtube-play-btn.png" alt="" className='img-fluid ' />
                                        </a>
                                    </div>
                                </div>
                                <div className="slider-item-heading">
                                    <h4>SPGS Installation (5 KW/48V) MPPT</h4>
                                    <p>SPGS Installation (5 KW/48V) by EAPRO Global Ltd.</p>
                                    <p>Know more: www.eapro.in</p>
                                    <p>Call: <a href="tel:9068563156">9068563156</a></p>
                                    <p>Customer care: <a href="tel:8650115000">8650115000</a></p>
                                </div>
                            </div>
                            <div className='slider-item-dv'>
                                <div className="slider-item-img">
                                    <img src="../images/kc-1.png" alt="" className='img-fluid' />
                                    <div className="play-btn-youtube">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                            <img src="../images/youtube-play-btn.png" alt="" className='img-fluid ' />
                                        </a>
                                    </div>
                                </div>
                                <div className="slider-item-heading">
                                    <h4>SPGS Installation (5 KW/48V) MPPT</h4>
                                    <p>SPGS Installation (5 KW/48V) by EAPRO Global Ltd.</p>
                                    <p>Know more: www.eapro.in</p>
                                    <p>Call: <a href="tel:9068563156">9068563156</a></p>
                                    <p>Customer care: <a href="tel:8650115000">8650115000</a></p>
                                </div>
                            </div>
                        </Slider>
                    </div>

                </div>
            </section>

            {/* ================================modal start here============================ */}
            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Video Heading</h5>
                            <button type="button" onClick={pauseVideo} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <iframe id='iframeyt' className='yt_player_iframe' width="100%" height="400" src="https://www.youtube.com/embed/eVLYvqBEAvg?enablejsapi=1" title="PWM vs MPPT - Which one to choose for your application?" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        {/* <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Understood</button>
                        </div> */}
                    </div>
                </div>
            </div>
            {/* ================================modal end here============================== */}
        </>
    )
}

export default KnowledgeCentrePageVideoTuto