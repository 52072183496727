import React from 'react'
import "./ECataloguePageBanner.css"
const ECataloguePageBanner = () => {
    return (
        <>
            <section className='e-catalogue-banner-section'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <div className="banner-images-left">
                                <img src="./images/e-catalogue/banner-img.png" alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-md-7 ">
                            <div className="business-heading-top">
                                <h5>Download our e-catalogue now for the latest products and information</h5>
                            </div>
                            <div className="business-heading text-center">
                                <img src="./images/global-footprints/heading-bg.png" alt="" />
                                <h1>Don't miss out</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ECataloguePageBanner