import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import EfficientMechanicalSolarBanner from './EfficientMechanicalSolarBanner/EfficientMechanicalSolarBanner'
import EfficientMechanicalSolarContent from './EfficientMechanicalSolarContent/EfficientMechanicalSolarContent'
import EfficientMechanicalSolarForms from './EfficientMechanicalSolarContent/EfficientMechanicalSolarForms'

const EfficientMechanicalSolar = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <EfficientMechanicalSolarBanner />
            <EfficientMechanicalSolarContent />
            <EfficientMechanicalSolarForms/>
            <Footer />
        </>
    )
}

export default EfficientMechanicalSolar