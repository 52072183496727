import React, { useEffect } from 'react'
import "./CareerAtEaproTwoContent.css"
import { Link } from 'react-router-dom'
const CareerAtEaproTwoContent = () => {
  return (
    <>
      <section className='career-at-eapro-two-con-search-jobs'>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <form action="">
                <div className="row">
                  <div className="col-md-6 text-start">
                    <label htmlFor="search-by-keyword-id" className='form-label'>Search by Keyword</label>
                    <input type="text" className="form-control" placeholder='' id='search-by-keyword-id' />
                  </div>
                  <div className="col-md-6 text-start">
                    <label htmlFor="search-by-location-id" className='form-label'>Search by Location</label>
                    <input type="text" className="form-control" placeholder='' id='search-by-location-id' />
                  </div>
                  <div className="col-md-12">
                    <div className="search-job-btn">
                      <a href="" className=''>Search Jobs</a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className='career-at-eapro-two-page-content-section'>
        <div className="container">
          <div className="form-parent-custon-dv text-start">
            <div className="card-parent-dv">
              <h4>Assistant Manager Corporate Sales / Mangalore</h4>
              <p><b>Date: 12 Jan 2024</b></p>
              <p><b>Location: Mangalore, South, India</b></p>
              <p><b>Company: Eapro Global Pvt. Ltd.</b></p>
              <div className="job-objective-dv">
                <p><b>Job Objective:</b></p>
                <p>The objective of the role is to enhance our existing client base for our Key Accounts through effective client engagement and client advocacy.</p>
              </div>
              <div className="job-objective-dv">
                <p><b>Designation/ Title</b></p>
                <p>Assistant Manager Corporate sales</p>
              </div>
              <div className="job-description-dv">
                <p><b>Job Description:</b></p>
                <ul>
                  <li>Key Accounts’ Engagement: Cultivate and nurture strong relationships with multiple stakeholders and key decision makers within the client ecosystem and achieve sales targets by leveraging the product mix through upselling and cross-selling.</li>
                  <li>Client Servicing: Implement recruitment campaigns and monitor product usage, conduct timely trainings and collect feedback from end users.</li>
                  <li>Closure and Collection: Manage prospects, negotiate and freeze on commercials taking them to a logical closure with required documentation and ensure timely collection.</li>
                  <li>Consultative Sales Approach: Develop an in depth understanding of the client’s business and their needs and propose customized Naukri solutions. Collaborate with various internal and external teams to deliver the best customer experience, monitor product performance and ROI and share necessary analytics with relevant stakeholders.</li>
                  <li>Process Compliance: Partner with other departments (i.e. Legal, Finance) to ensure process compliance and adherence to guidelines.</li>
                </ul>
              </div>
              <div className="job-description-dv">
                <p><b>Required Skills:</b></p>
                <ul>
                  <li>Field sales</li>
                  <li>Software sales</li>
                  <li>Solutions sales</li>
                  <li>Enterprise sales</li>
                </ul>
              </div>
              <div className="job-description-dv">
                <p><b>Why join us:</b></p>
                <ul>
                  <li>Work life balance</li>
                  <li>Entrepreneurial environment with high potential for learning & career growth</li>
                  <li>High visibility role with complete ownership</li>
                </ul>
              </div>
              <div className="job-apply-btn">
                <Link to="/career-at-eapro-form">Apply Now <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708"/>
  <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></Link>
              </div>
            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default CareerAtEaproTwoContent