import React from 'react'
import "./ECataloguePageContent.css"
import ECatalogueCard from './ECatalogueCard'
const ECataloguePageContent = () => {
    return (
        <>
            <section className='e-catalogue-page-content-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="e-catalogue-heading">
                                <h5>Catalogue</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-lg-4 my-2">
                            <ECatalogueCard />
                        </div>
                        <div className="col-md-4 col-lg-4 my-2">
                            <ECatalogueCard />
                        </div>
                        <div className="col-md-4 col-lg-4 my-2">
                            <ECatalogueCard />
                        </div>
                        <div className="col-md-4 col-lg-4 my-2">
                            <ECatalogueCard />
                        </div>
                        <div className="col-md-4 col-lg-4 my-2">
                            <ECatalogueCard />
                        </div>
                        <div className="col-md-4 col-lg-4 my-2">
                            <ECatalogueCard />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ECataloguePageContent