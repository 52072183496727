import React from 'react'
import "./HelpAndSupport.css"
import { Link } from 'react-router-dom'
const HelpAndSupport = () => {
  return (
    <>
      <section className='help-and-support-section'>
        <div className="container">
          <div className="heading-dv">
            <h5>Help & Suport</h5>
          </div>
          <div className="help-support-inner-section">
            <div className="row align-items-center">
              <div className="col-md-10 col-lg-10 col-12 offset-md-1 offset-lg-1 ">
                <div className="row">
                  <div className="col-md-6">
                    <div className="first-card-parent">
                      <div className="mobile-tag">
                        <p><a href="tel:8650115000"><img src="../images/phone.png" alt="" />8650115000</a></p>
                        <p><img src="../images/alarm.png" alt="" />09:00 AM to 06:00 PM </p>
                      </div>
                    </div>
                    <div className="first-card-parent">
                      <div className="mobile-tag">
                        <p><a href="https://api.whatsapp.com/send?phone=919068563156" target='_blank'><img src="../images/whatsapp.png" alt="" />+91-9068513651</a></p>
                      </div>
                    </div>
                    <div className="first-card-parent">
                      <div className="mobile-tag">
                        <p><a href="mailto:info@eapro.in"><img src="../images/mail.png" alt="" />info@eapro.in</a></p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="second-side-card-parent">
                      <div className="mobile-tag">
                        <p>
                          <Link to="/register-your-complaint"><img src="../images/book-complaint.png" alt="" />Book a complaint</Link>
                          <Link to="/register-your-complaint"><i class="fa fa-chevron-right" aria-hidden="true"></i><i class="fa fa-chevron-right" aria-hidden="true"></i></Link>

                        </p>
                      </div>
                    </div>
                    <div className="second-side-card-parent">
                      <div className="mobile-tag">
                        <p>
                          <a href="#"><img src="../images/register-product.png" alt="" />Register your product</a>
                          <a href="#"><i class="fa fa-chevron-right" aria-hidden="true"></i><i class="fa fa-chevron-right" aria-hidden="true"></i></a>
                        </p>
                      </div>
                    </div>
                    <div className="second-side-card-parent">
                      <div className="mobile-tag">
                        <p>
                          <Link to="/purchase-amc"><img src="../images/buy-amc.png" alt="" />Buy AMC</Link>
                          <Link to="/purchase-amc"><i class="fa fa-chevron-right" aria-hidden="true"></i><i class="fa fa-chevron-right" aria-hidden="true"></i></Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default HelpAndSupport