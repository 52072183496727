import React from 'react'
import "./SolarSolutionPageDetails.css"
const SolarSolutionPageDetails = () => {
    return (
        <>
            <section className='solar-solution-page-details-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="career-content-heading">
                                <h5>Solar Solution</h5>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="power-solution-details">
                                <p>Experience hassle-free days with EAPRO, the premier brand for top-notch power solutions in India. EAPRO's commitment to innovation and cutting-edge technology is evident in its products, meticulously crafted for both excellence and user convenience. With over 11 years of industry experience, EAPRO has brightened countless Indian households and anticipates this impact to grow even more in the coming years. From Inverters to Inverter batteries, EAPRO's power solution lineup encompasses an extensive array of products tailored to meet all your requirements.</p>
                            </div>
                            <div className="book-a-free-consultation">
                                <a href="#">Book A Free Consultation</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SolarSolutionPageDetails