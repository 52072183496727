import React from 'react'
import "./TRCEfficientServicesContent.css"
const TRCEfficientServicesContent = () => {
    return (
        <>
            <section className='trc-global-energy-con-page-content-section'>
                <div className="container">
                    <div className="global-energy-heading">
                        <h5>TRC Efficient & Services Pvt. Ltd.</h5>
                    </div>
                    <div className="top-para">
                        <p>Welcome to Eapro Global Energy Pvt Ltd, a pioneering force in the realm of sustainable energy solutions in India. Established in 2023 as a specialized entity under the umbrella of Eapro Group, we are dedicated to bringing the cutting-edge products of Eapro to the Indian market through a strategic blend of channel sales and e-commerce.</p>
                    </div>
                    <div className="icon-row-parent row">
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card">
                                    <img src="./images/global-energy/mission.png" alt="" />
                                    <h4>OUR  <span>MISSION</span></h4>
                                </div>
                                <div className="dark-icons-content">
                                    <img src="./images/global-energy/dark-mission.png" alt="" />
                                    <p>Our mission is clear - to be the backbone of operational efficiency for Eapro Group and beyond. We are dedicated to providing comprehensive services and AMC support that not only meet but exceed the expectations of our clients. By doing so, we aim to contribute to the success of our clients and foster long-term, mutually beneficial relationships.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card">
                                    <img src="./images/global-energy/vision.png" alt="" />
                                    <h4>OUR  <span>Vision</span></h4>
                                </div>
                                <div className="dark-icons-content">
                                    <img src="./images/global-energy/dark-vision.png" alt="" />
                                    <p>At TRC Efficient & Services Pvt Ltd, our vision is to redefine service excellence. We aspire to be the preferred partner for Eapro Group and other companies, offering a seamless blend of technical expertise, reliability, and innovation. Our goal is to set new standards in the service sector and become a trusted name synonymous with unparalleled support</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card last-icon-heading-card">
                                    <img src="./images/global-energy/Values.png" alt="" />
                                    <h4>OUR  <span>Values</span></h4>
                                </div>
                                <div className="dark-icons-content last-dark-icons-content">
                                    <img src="./images/global-energy/dark-Values.png" alt="" />
                                    <p>Excellence: We are driven by a commitment to excellence in every aspect of our services. From technical proficiency to customer support, we strive for perfection to ensure the satisfaction of our clients.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="icon-row-parent row mt-5">
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card">
                                    <img src="./images/global-energy/reliability.png" alt="" />
                                    <h4>OUR  <span>Reliability</span></h4>
                                </div>
                                <div className="dark-icons-content">
                                    <img src="./images/global-energy/dark-reliability.png" alt="" />
                                    <p>Trust is the cornerstone of our services. Clients rely on us for timely and efficient support, and we take pride in delivering on our promises with unwavering reliability.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card">
                                    <img src="./images/global-energy/sustainability.png" alt="" />
                                    <h4>OUR  <span>Sustainability</span></h4>
                                </div>
                                <div className="dark-icons-content">
                                    <img src="./images/global-energy/dark-sustainability.png" alt="" />
                                    <p>In a rapidly evolving landscape, we embrace innovation as a means to enhance our services continually. By staying at the forefront of industry trends, we ensure that our clients receive cutting-edge solutions.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="icon-heading-parent">
                                <div className="icon-heading-card last-icon-heading-card">
                                    <img src="./images/global-energy/collaboration.png" alt="" />
                                    <h4>OUR  <span>Collaboration</span></h4>
                                </div>
                                <div className="dark-icons-content last-dark-icons-content">
                                    <img src="./images/global-energy/dark-collaboration.png" alt="" />
                                    <p>We understand the importance of collaboration. Working closely with Eapro Group and other clients, we aim to build strong partnerships that contribute to the overall success of our clients' operations.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TRCEfficientServicesContent