import React from 'react'
import "./StoreLocatorContent.css"
import StoreLocatorContentCard from './StoreLocatorContentCard'
const StoreLocatorContent = () => {
  return (
    <>
      <section className='store-locator-content-section'>
        <StoreLocatorContentCard />
        <StoreLocatorContentCard />
        <StoreLocatorContentCard />
        <StoreLocatorContentCard />
        <StoreLocatorContentCard />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="see-more-btn">
                  <a href="#">See More</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default StoreLocatorContent