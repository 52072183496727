import React from 'react'
import "./HelpAndSupportContent.css"
import HelpAndSupport from '../../HomePageComponent/HelpAndSupport/HelpAndSupport'
import { Link } from 'react-router-dom'
const HelpAndSupportContent = () => {
    return (
        <>
            <section className='help-and-support-content-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="help-support-heading">
                                <h5>Customer Support</h5>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="help-support-content-dv">
                                <p>At Eapro Care, our commitment shines through exceptional pre and post-sales assistance. Reach out to us for unparalleled customer support that goes beyond. We eagerly await your connection, ready to listen and assist. Your satisfaction is our pride.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="services-card-parent-yellow">
                                <div className="services-images">
                                    <img src="./images/help-and-support/customer-service.png" alt="" className='img-fluid' />
                                </div>
                                <div className="services-heading">
                                    <p>Service care <i class="fa fa-angle-right" aria-hidden="true"></i></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="services-card-parent-black">
                                <div className="services-images">
                                    <img src="./images/help-and-support/location.png" alt="" className='img-fluid' />
                                </div>
                                <div className="services-heading">
                                    <p>Store Locator <i class="fa fa-angle-right" aria-hidden="true"></i></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="services-card-parent-yellow">
                                <div className="services-images">
                                    <img src="./images/help-and-support/service-center.png" alt="" className='img-fluid' />
                                </div>
                                <div className="services-heading">
                                    <p>Service center locator <i class="fa fa-angle-right" aria-hidden="true"></i></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="services-card-parent-black">
                                <div className="services-images">
                                    <img src="./images/help-and-support/contact-mail.png" alt="" className='img-fluid' />
                                </div>
                                <div className="services-heading">
                                    <p>Contact us <i class="fa fa-angle-right" aria-hidden="true"></i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='help-and-support-section-inner'>
                    <div className="container">
                        <div className="heading-dv">
                            <h5>Help & Suport</h5>
                        </div>
                        <div className="help-support-inner-section">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="first-card-parent">
                                        <div className="mobile-tag">
                                            <p><a href="tel:8650115000"><img src="../images/phone.png" alt="" />8650115000</a></p>
                                            <p><img src="../images/alarm.png" alt="" />09:00 AM to 06:00 PM </p>
                                        </div>
                                    </div>
                                    <div className="first-card-parent">
                                        <div className="mobile-tag">
                                            <p><a href="https://api.whatsapp.com/send?phone=919068563156" target='_blank'><img src="../images/whatsapp.png" alt="" />+91-9068513651</a></p>
                                        </div>
                                    </div>
                                    <div className="first-card-parent">
                                        <div className="mobile-tag">
                                            <p><a href="mailto:info@eapro.in"><img src="../images/mail.png" alt="" />info@eapro.in</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="second-side-card-parent">
                                        <div className="mobile-tag">
                                            <p>
                                                <Link to="/register-your-complaint"><img src="../images/book-complaint.png" alt="" />Book a complaint</Link>
                                                <Link to="/register-your-complaint"><i class="fa fa-chevron-right" aria-hidden="true"></i><i class="fa fa-chevron-right" aria-hidden="true"></i></Link>

                                            </p>
                                        </div>
                                    </div>
                                    <div className="second-side-card-parent">
                                        <div className="mobile-tag">
                                            <p>
                                                <a href="#"><img src="../images/register-product.png" alt="" />Register your product</a>
                                                <a href="#"><i class="fa fa-chevron-right" aria-hidden="true"></i><i class="fa fa-chevron-right" aria-hidden="true"></i></a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="second-side-card-parent">
                                        <div className="mobile-tag">
                                            <p>
                                                <Link to="/purchase-amc"><img src="../images/buy-amc.png" alt="" />Buy AMC</Link>
                                                <Link to="/purchase-amc"><i class="fa fa-chevron-right" aria-hidden="true"></i><i class="fa fa-chevron-right" aria-hidden="true"></i></Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HelpAndSupportContent