import React from 'react'
import "./DesignSolarPowerLoadCal.css"
import "./ResponsiveDesignSolarPowerLoadCal.css"
const DesignSolarPowerLoadCal = () => {
    return (
        <>
            <section className='design-solar-power-load-calculator-section'>
                <div className="design-before-row-dv">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3"></div>
                            <div className="col-lg-9">
                                <div className="content-parent-dv">
                                    <div className="content-inner-first">
                                        <h4>We Can Help You Find The Right Power & Solar Solution</h4>
                                        <h5>Our Team Of  Experts Or Simple Calculator Can Help You Understand The Over All Load Requirements </h5>
                                    </div>
                                    <div className="content-inner-second">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="second-inner-button">
                                                    <a href="#">
                                                        <img src="../images/solar-system.png" alt="" /> Design Your Solar System
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="second-inner-button">
                                                    <a href="#">
                                                        <img src="../images/power-system.png" alt="" /> Design Your Power System
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="second-inner-button">
                                                    <a href="#">
                                                        <img src="../images/load-calculator.png" alt="" /> Load Calculator
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DesignSolarPowerLoadCal