import React from 'react'
import "./AboutBannerCompo.css"
const AboutBannerCompo = () => {
  return (
    <>
        <section className='about-banner-parent-section'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-3">
                        <div className="year-of-dv-parent">
                            <img src="./images/about-us/11-years.png" alt="" className='img-fluid'/>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="years-of-trust-content">
                            <h1>11 years of trust in our technology innovative, reliable, customer-centric, empowering businesses worldwide.</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default AboutBannerCompo