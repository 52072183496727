import React from 'react'
import "./PowerSolutionBatteryPage.css"
const PowerSolutionBatteryPage = () => {
    return (
        <>
            <section className='power-solution-battery-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="specialization-heading-dv">
                                <h5>Battery</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="right-content-parent">
                                <p>"Vajra Battery" is a high-performance, cutting-edge energy storage solution designed to meet the demanding power needs of various applications, from portable electronics to renewable energy systems. The name "Vajra" is derived from a Sanskrit term meaning "indestructible" or "diamond-like," implying the battery's durability, strength, and reliability.</p>
                                <div className="row">
                                    <div className="col-md-6 mb-lg-0 mb-md-0 mb-3">
                                        <div className="img-content-dv">
                                            <img src="./images/power-solution/automatic-stabilizer.png" alt="" className='img-fluid' />
                                            <p>Longer Battery Life</p>
                                        </div>
                                        <div className="img-content-dv mt-4">
                                            <img src="./images/power-solution/power.png" alt="" className='img-fluid' />
                                            <p>EXTRA 15% POWER THROUGH EMERGENCY POWER BOOST</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-lg-0 mb-md-0 mb-3">
                                        <div className="img-content-dv">
                                            <img src="./images/power-solution/battery.png" alt="" className='img-fluid' />
                                            <p>Power Saving</p>
                                        </div>
                                        <div className="img-content-dv mt-4">
                                            <img src="./images/power-solution/pure-sine.png" alt="" className='img-fluid' />
                                            <p>PURE SINE WAVE TECHNOLOGY</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <div className="view-product-learn-more">
                                            <a href="#" className='view-product-btn'>View Product</a>
                                            <a href="#" className='view-product-btn'>Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="left-images">
                                <img src="./images/power-solution/battery-img.png" alt="" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PowerSolutionBatteryPage