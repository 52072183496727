import React, { useEffect,useState } from 'react';
import './Navbar.css';
import './ResponsiveNavbar.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';

const MobileNavbar = () => {
    $("body").on("click tap", "button#menu-toggle", function () {
        $("header").addClass("active");
    });

    $("body").on("click tap", "#close-sidebar", function (e) {
        if (!$(e.target).is("button#menu-toggle")) {
            $("header").removeClass("active");
            $("div.menu-panel").removeClass("is-active");
        }
    });

    $("body").on("click tap", "nav.slide-out-menu", function (e) {
        e.stopPropagation();
    });

    $("body").on("click tap", ".menu-link", function () {
        $("div.menu-panel").removeClass("is-active");

        if ($(this).data("ref")) {
            var targetRef = $(this).data("ref");
            var $target = $('div.menu-panel[data-menu="' + targetRef + '"]');

            $target.addClass("is-active");
        }
    });
    // $(".search-input-with-icon").hide();


    // const[scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if(window.scrollY > 1) {
                $('.search-input-with-icon').fadeOut();
            }else{
                $('.search-input-with-icon').fadeIn();
            }
        });
         
    }, []);
    $("body").on("click tap", "#search-btn-id", function () {
        $(".search-input-with-icon").toggle();
    });
    return (
        <>
            <section className="mobile-navbar-section d-block d-md-block d-lg-none">
                <header>
                    <nav class="navbar">
                        <div className="navbar-brand-img">
                            <button type="button" className='menu-toggle-btn fa fa-bars' id="menu-toggle" aria="menu"></button>
                            <div className='logo-cus'>
                                <Link to="/"><img src="../images/logo.png" alt="" /></Link>
                            </div>
                            <div className="cart-search-icon">
                                <a className="nav-link" href="#" id="search-btn-id">
                                    <i className='fa fa-search text-white'></i>
                                </a>
                                {/* <a className="nav-link" href="#">
                                    <img src="../images/user.png" alt="" />
                                </a> */}
                                {/* <a className="nav-link" href="#">
                                    <img src="../images/cart.png" alt="" />
                                </a> */}
                            </div>
                        </div>
                        <div className="search-input-with-icon">
                            <form action="">
                                <input type="text" className='search-input' placeholder='Search for a product' />
                                <div className="icon-dv">
                                    <button type='button'><i className='fa fa-search'></i></button>
                                </div>
                            </form>
                        </div>
                        <nav class="slide-out-menu">
                            <div className="mobile-sidebar-logo-dv">
                                <div className="navbar-brand-img">
                                    <div className='logo-cus'>
                                        <Link to="/"><img src="../images/logo.png" alt="" /></Link>
                                    </div>
                                    <div className="cart-search-icon">
                                        <a href="#" id='close-sidebar' className='fa fa-times'></a>
                                    </div>
                                </div>
                            </div>
                            <div class="menu-panels">
                                <div class="primary-menu-panel">
                                    <ul>
                                        <li>
                                            <a type="button" class="menu-link" data-ref="power-solution">
                                                <img src="./images/power-solution.png" alt="" className='menu-img-icon' /> Power Solution
                                                <svg class="arrow-right d-sm-ib" fill="#111" height="30px" width="30px" viewBox="0 0 185.4 300"><path d="M7.3 292.7c-9.8-9.8-9.8-25.6 0-35.4L114.6 150 7.3 42.7c-9.8-9.8-9.8-25.6 0-35.4s25.6-9.8 35.4 0L185.4 150 42.7 292.7c-4.9 4.8-11.3 7.3-17.7 7.3-6.4 0-12.7-2.5-17.7-7.3z"></path></svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a type="button" class="menu-link" data-ref="solar-solution">
                                                <img src="./images/solar-energy.png" alt="" className='menu-img-icon' /> Solar Solution
                                                <svg class="arrow-right d-sm-ib" fill="#111" height="30px" width="30px" viewBox="0 0 185.4 300"><path d="M7.3 292.7c-9.8-9.8-9.8-25.6 0-35.4L114.6 150 7.3 42.7c-9.8-9.8-9.8-25.6 0-35.4s25.6-9.8 35.4 0L185.4 150 42.7 292.7c-4.9 4.8-11.3 7.3-17.7 7.3-6.4 0-12.7-2.5-17.7-7.3z"></path></svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a type="button" class="menu-link" data-ref="store-locator">
                                                <img src="./images/store-location.png" alt="" className='menu-img-icon' />  Store Locator
                                                <svg class="arrow-right d-sm-ib" fill="#111" height="30px" width="30px" viewBox="0 0 185.4 300"><path d="M7.3 292.7c-9.8-9.8-9.8-25.6 0-35.4L114.6 150 7.3 42.7c-9.8-9.8-9.8-25.6 0-35.4s25.6-9.8 35.4 0L185.4 150 42.7 292.7c-4.9 4.8-11.3 7.3-17.7 7.3-6.4 0-12.7-2.5-17.7-7.3z"></path></svg>
                                            </a>
                                        </li>
                                        <li>
                                            <a type="button" class="menu-link" data-ref="catalogue">
                                                <img src="./images/catalogue.png" alt="" className='menu-img-icon' />  Catalogue
                                                <svg class="arrow-right d-sm-ib" fill="#111" height="30px" width="30px" viewBox="0 0 185.4 300"><path d="M7.3 292.7c-9.8-9.8-9.8-25.6 0-35.4L114.6 150 7.3 42.7c-9.8-9.8-9.8-25.6 0-35.4s25.6-9.8 35.4 0L185.4 150 42.7 292.7c-4.9 4.8-11.3 7.3-17.7 7.3-6.4 0-12.7-2.5-17.7-7.3z"></path></svg>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="start-second-menu-aria">
                                    <ul>
                                        <li>
                                            <a href="#"> <img src="./images/login.png" alt="" className='menu-img-icon' /> Login</a>
                                        </li>
                                        <li>
                                            <Link to="/business-division"> <img src="./images/business-division.png" alt="" className='menu-img-icon' /> Business Divisions</Link>
                                        </li>
                                        <li>
                                            <Link to="/help-and-support"> <img src="./images/technical-support.png" alt="" className='menu-img-icon' /> Support</Link>
                                        </li>
                                        <li>
                                            <Link to="/career"> <img src="./images/career.png" alt="" className='menu-img-icon' /> Career</Link>
                                        </li>
                                        <li>
                                            <Link to="/knowledge-center"> <img src="./images/knowledge-center.png" alt="" className='menu-img-icon' /> Knowledge Center</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div class="menu-panel" data-menu="power-solution">
                                    <div className="mobile-sidebar-logo-dv">
                                        <div className="navbar-brand-img">
                                            <div className='logo-cus'>
                                                <Link to="/"><img src="../images/logo.png" alt="" /></Link>
                                            </div>
                                            <div className="cart-search-icon">
                                                <a href="#" id='close-sidebar' className='fa fa-times'></a>
                                            </div>
                                        </div>
                                    </div>
                                    <Link to="/power-solution" type="button" class="menu-link menu-header">
                                        <svg class="arrow-left" fill="#111" height="30px" width="30px" viewBox="0 0 185.4 300"><path d="M160.4 300c-6.4 0-12.7-2.5-17.7-7.3L0 150 142.7 7.3c9.8-9.8 25.6-9.8 35.4 0 9.8 9.8 9.8 25.6 0 35.4L70.7 150 178 257.3c9.8 9.8 9.8 25.6 0 35.4-4.9 4.8-11.3 7.3-17.6 7.3z"></path></svg>
                                        Power Solution
                                    </Link>
                                    <ul>
                                        <li><a href="#">Profile</a></li>
                                        <li><a href="#">Account Balance</a></li>
                                        <li><a href="#">Settings</a></li>
                                    </ul>
                                </div>
                                <div class="menu-panel" data-menu="solar-solution">
                                    <div className="mobile-sidebar-logo-dv">
                                        <div className="navbar-brand-img">
                                            <div className='logo-cus'>
                                                <Link to="/"><img src="../images/logo.png" alt="" /></Link>
                                            </div>
                                            <div className="cart-search-icon">
                                                <a href="#" id='close-sidebar' className='fa fa-times'></a>
                                            </div>
                                        </div>
                                    </div>
                                    <Link to="/solar-solution" type="button" class="menu-link menu-header">
                                        <svg class="arrow-left" fill="#111" height="30px" width="30px" viewBox="0 0 185.4 300"><path d="M160.4 300c-6.4 0-12.7-2.5-17.7-7.3L0 150 142.7 7.3c9.8-9.8 25.6-9.8 35.4 0 9.8 9.8 9.8 25.6 0 35.4L70.7 150 178 257.3c9.8 9.8 9.8 25.6 0 35.4-4.9 4.8-11.3 7.3-17.6 7.3z"></path></svg>
                                        Solar Solution
                                    </Link>
                                    <ul>
                                        <li><a href="#">Contact Us</a></li>
                                        <li><a href="#">Help Centre</a></li>
                                        <li><a href="#">FAQ</a></li>
                                    </ul>
                                </div>
                                <div class="menu-panel" data-menu="store-locator">
                                    <div className="mobile-sidebar-logo-dv">
                                        <div className="navbar-brand-img">
                                            <div className='logo-cus'>
                                                <Link to="/"><img src="../images/logo.png" alt="" /></Link>
                                            </div>
                                            <div className="cart-search-icon">
                                                <a href="#" id='close-sidebar' className='fa fa-times'></a>
                                            </div>
                                        </div>
                                    </div>
                                    <Link to="/store-locator" type="button" class="menu-link menu-header">
                                        <svg class="arrow-left" fill="#111" height="30px" width="30px" viewBox="0 0 185.4 300"><path d="M160.4 300c-6.4 0-12.7-2.5-17.7-7.3L0 150 142.7 7.3c9.8-9.8 25.6-9.8 35.4 0 9.8 9.8 9.8 25.6 0 35.4L70.7 150 178 257.3c9.8 9.8 9.8 25.6 0 35.4-4.9 4.8-11.3 7.3-17.6 7.3z"></path></svg>
                                        Store Locator
                                    </Link>
                                    <ul>
                                        <li><a href="#">Birthday</a></li>
                                        <li><a href="#">Christmas</a></li>
                                        <li><a href="#">General</a></li>
                                    </ul>
                                </div>
                                <div class="menu-panel" data-menu="catalogue">
                                    <div className="mobile-sidebar-logo-dv">
                                        <div className="navbar-brand-img">
                                            <div className='logo-cus'>
                                                <Link to="/"><img src="../images/logo.png" alt="" /></Link>
                                            </div>
                                            <div className="cart-search-icon">
                                                <a href="#" id='close-sidebar' className='fa fa-times'></a>
                                            </div>
                                        </div>
                                    </div>
                                    <Link to="/e-catalogue" type="button" class="menu-link menu-header">
                                        <svg class="arrow-left" fill="#111" height="30px" width="30px" viewBox="0 0 185.4 300"><path d="M160.4 300c-6.4 0-12.7-2.5-17.7-7.3L0 150 142.7 7.3c9.8-9.8 25.6-9.8 35.4 0 9.8 9.8 9.8 25.6 0 35.4L70.7 150 178 257.3c9.8 9.8 9.8 25.6 0 35.4-4.9 4.8-11.3 7.3-17.6 7.3z"></path></svg>
                                        Catalogue
                                    </Link>
                                    <ul>
                                        <li><a href="#">Birthday</a></li>
                                        <li><a href="#">Christmas</a></li>
                                        <li><a href="#">General</a></li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </nav>
                </header>
            </section>

        </>
    )
}

export default MobileNavbar