import React from 'react'
import "./AboutUsContentDark.css"
const AboutUsContentDark = () => {
    return (
        <>
            <section className='about-us-content-dark-section'>
                <div className='container-fluid container-xxl'>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="left-images-dv">
                                <img src="./images/about-us/corporate-office.jpg" alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="right-about-content-dv">
                                <div className="about-heading-dv">
                                    <h5>About Us</h5>
                                </div>
                                <div className="about-content">
                                    <p>Welcome To Energy Automation Products & Research Organization (EAPRO), Founded In 2012 With A Clear Vision: To Make Futuristic, Eco-Friendly Products Accessible To All. We Strive To Bring Sustainable Solutions That Transform Lives And Safeguard The Planet, Without Breaking The Bank. EAPRO' S Innovative Team Is Committed To Research, Development, And Manufacturing Of Energy-Efficient Technologies, Tailored For Your Needs. As A Trusted Name In The Industry, We Are Dedicated To Delivering Top-Quality Products At Affordable Costs. Join Us On This Green Journey, And Together, Let's Create A Brighter, Cleaner Future For Generations To Come.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUsContentDark