import React from 'react'
import './PowerSolutionPageBanner.css'
import './ResponsivePowerSolutionPageBanner.css'
const PowerSolutionPageBanner = () => {
    return (
        <>
            {/* <section className='power-solution-page-banner-section'>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 text-start">
                            <div className="business-heading text-start">
                                <img src="./images/power-solution/heading-bg.png" alt="" className='img-fluid' />
                                <div className='business-banner-heading'>
                                    <h1>Power Solution</h1>
                                    <h5>"Efficient power solutions for a sustainable and brighter future ahead."</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className='power-solution-page-banner-section'>
                <div className=" align-items-center">
                    <div className="text-start">
                        <div className="banner-top-img-bg-rel">
                            <img src="./images/power-solution/banner-bg-1.jpg" alt="" className='img-fluid' />
                            <div className="banner-top-content-absol business-heading text-start">
                                <img src="./images/power-solution/heading-bg.png" alt="" className='img-fluid banner-img-tag'/>
                                <div className='business-banner-heading'>
                                    <div className="container">
                                        <h1>Power Solution</h1>
                                        <h5>"Efficient power solutions for a sustainable and brighter future ahead."</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PowerSolutionPageBanner