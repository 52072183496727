import React from 'react'
import "./GlobalFootprintsBanner.css"
const GlobalFootprintsBanner = () => {
  return (
    <>
     <section className='global-footprint-banner-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="store-heading">
                                <img src="./images/global-footprints/heading-bg.png" alt="" />
                                <h5>Global Footprints</h5>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="banner-img-dv">
                                <img src="./images/global-footprints/map.png" alt="" className='img-fluid'/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  )
}

export default GlobalFootprintsBanner