import React from 'react'
import "./TRCEfficientServicesBanner.css"
import "./ResponsiveTRCEfficientServicesBanner.css"
const TRCEfficientServicesBanner = () => {
    return (
        <>
            <section className='trc-energy-banner-section'>
                {/* <div className="container-fluid"> */}
                    {/* <div className="row align-items-center"> */}
                        <div className="col-md-12 text-start">
                            <div className="business-heading text-start">
                                <img src="./images/global-energy/trc-banner.png" alt="" className='img-fluid' />
                                {/* <h1>Help & Support</h1> */}
                            </div>
                        </div>
                    {/* </div> */}
                {/* </div> */}
            </section>
        </>
    )
}

export default TRCEfficientServicesBanner