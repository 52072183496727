import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import BlogDetailsBanner from './BlogDetailsBanner/BlogDetailsBanner'
import BlogDetailsContent from './BlogDetailsContent/BlogDetailsContent'

const BlogDetails = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <BlogDetailsBanner/>
            <BlogDetailsContent/>
            <Footer />
        </>
    )
}

export default BlogDetails