import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Navigate, Route, Link } from 'react-router-dom';
import Home from './Component/HomePage/Home';
import AboutUs from './Component/AboutUSPageComponent/AboutUs';
import StoreLocatorPage from './Component/StoreLocatorPageComponent/StoreLocatorPage';
import GlobalFootprintsPage from './Component/GlobalFootprintsPage/GlobalFootprintsPage';
import BussinessDivisionsPage from './Component/BussinessDivisionsPage/BussinessDivisionsPage';
import ECataloguePage from './Component/ECataloguePage/ECataloguePage';
import HelpAndSupportPage from './Component/HelpAndSupportPage/HelpAndSupportPage';
import CareerPage from './Component/CareerPage/CareerPage';
import RegisterYourComplaintPage from './Component/RegisterYourComplaintPage/RegisterYourComplaintPage';
import PurchaseAMCPage from './Component/PurchaseAMCPage/PurchaseAMCPage';
import PurchaseAMCPage1 from './Component/PurchaseAMCPage1/PurchaseAMCPage1';
import PurchaseAMCPage3 from './Component/PurchaseAMCPage3/PurchaseAMCPage3';
import KnowledgeCentrePage from './Component/KnowledgeCentrePage/KnowledgeCentrePage';
import PowerSolutionPage from './Component/PowerSolutionPage/PowerSolutionPage';
import SolarSolutionPage from './Component/SolarSolutionPage/SolarSolutionPage';
import Blog from './Component/Blog/Blog';
import BlogDetails from './Component/BlogDetails/BlogDetails';
import EaproGlobalEnergy from './Component/EaproGlobalEnergy/EaproGlobalEnergy';
import TRCEfficientServices from './Component/TRCEfficientServices/TRCEfficientServices';
import EAPROOverseas from './Component/EAPROOverseas/EAPROOverseas';
import ServiceCenterLocatorPagePage from './Component/ServiceCenterLocatorPageComponent/ServiceCenterLocatorPagePage';
import InstallationHelpPage from './Component/InstallationHelpPage/InstallationHelpPage';
import ContactUsPage from './Component/ContactUsPage/ContactUsPage';
import EaproGlobalLtd from './Component/EaproGlobalLtd/EaproGlobalLtd';
import EfficientMechanicalSolar from './Component/EfficientMechanicalSolar/EfficientMechanicalSolar';
import EventPage from './Component/EventPage/EventPage';
import EventPageDetails from './Component/EventPageDetails/EventPageDetails';
import CareerAtEaproOne from './Component/CareerAtEapro/CareerAtEaproOne/CareerAtEaproOne';
import CareerAtEaproTwo from './Component/CareerAtEapro/CareerAtEaproTwo/CareerAtEaproTwo';
import CareerAtEaproThree from './Component/CareerAtEapro/CareerAtEaproThree/CareerAtEaproThree';
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path='/' element={<Home />}></Route>
          <Route exact path='/about-us' element={<AboutUs />}></Route>
          <Route exact path='/store-locator' element={<StoreLocatorPage />}></Route>
          <Route exact path='/global-footprints' element={<GlobalFootprintsPage />}></Route>
          <Route exact path='/business-division' element={<BussinessDivisionsPage />}></Route>
          <Route exact path='/e-catalogue' element={<ECataloguePage />}></Route>
          <Route exact path='/help-and-support' element={<HelpAndSupportPage />}></Route>
          <Route exact path='/career' element={<CareerPage />}></Route>
          <Route exact path='/register-your-complaint' element={<RegisterYourComplaintPage />}></Route>
          <Route exact path='/purchase-amc' element={<PurchaseAMCPage />}></Route>
          <Route exact path='/purchase-amc1' element={<PurchaseAMCPage1 />}></Route>
          <Route exact path='/purchase-amc3' element={<PurchaseAMCPage3 />}></Route>
          <Route exact path='/knowledge-center' element={<KnowledgeCentrePage />}></Route>
          <Route exact path='/power-solution' element={<PowerSolutionPage />}></Route>
          <Route exact path='/solar-solution' element={<SolarSolutionPage />}></Route>
          <Route exact path='/blog' element={<Blog />}></Route>
          <Route exact path='/blog-details' element={<BlogDetails />}></Route>
          <Route exact path='/global-energy' element={<EaproGlobalEnergy />}></Route>
          <Route exact path='/global-energy-ltd' element={<EaproGlobalLtd />}></Route>
          <Route exact path='/trc-efficient-services' element={<TRCEfficientServices />}></Route>
          <Route exact path='/efficient-mechanical-solar' element={<EfficientMechanicalSolar />}></Route>
          <Route exact path='/eapro-overseas' element={<EAPROOverseas />}></Route>
          <Route exact path='/installation-help' element={<InstallationHelpPage />}></Route>
          <Route exact path='/contact-us' element={<ContactUsPage />}></Route>
          <Route exact path='/service-center-locator' element={<ServiceCenterLocatorPagePage />}></Route>
          <Route exact path='/news-event' element={<EventPage />}></Route>
          <Route exact path='/news-event-details' element={<EventPageDetails />}></Route>
          <Route exact path='/career-at-eapro-form' element={<CareerAtEaproOne />}></Route>
          <Route exact path='/career-at-eapro-details' element={<CareerAtEaproTwo />}></Route>
          <Route exact path='/career-at-eapro' element={<CareerAtEaproThree />}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
