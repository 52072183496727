import React from 'react'
import "./CareerAtEaproOneBanner.css"
const CareerAtEaproOneBanner = () => {
    return (
        <>
            <section className='career-at-eapro-banner-section'>
                <div className="col-md-12 text-start">
                    <div className="banner-top-img-bg-rel text-start">
                        <img src="./images/career/career-one-banner-blank.png" alt="" className='img-fluid' />
                        <div className="banner-top-content-absol">
                            <div className="container">
                                <h3>Careers at EAPRO</h3>
                                <p>Welcome to the EAPRO family! We're always on the lookout for talented individuals to join our team and contribute to our success. If you're passionate, creative, and eager to make a difference, we'd love to hear from you.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CareerAtEaproOneBanner