import React from 'react'
import "./BussinessDivisionContent.css"
const BussinessDivisionContent = () => {
    return (
        <>
            <section className='business-division-content-section'>
                <div className='container'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="business-division-content-heading-dv">
                                <h5>Technology Consultancy</h5>
                            </div>
                        </div>
                        <div className="col-md-10 offset-md-1">
                            <div className="row dv-left-right-division-content-row align-items-start">
                                <div className="col-md-6">
                                    <div className="dv-left-content">
                                        <p>Through our world-class Research & Development Center. We provide Technology Consultancy to most of the reputed brands globally. We intend to spread the best technology worldwide for Solar Power Generating Systems and improving Battery Efficiency.</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="dv-right-image">
                                        <img src="./images/business-division/technoly-consultacy.png" alt="" className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="business-division-content-heading-dv">
                                <h5>Orignal Design Manufacturing (ODM)</h5>
                            </div>
                        </div>
                        <div className="col-md-10 offset-md-1">
                            <div className="row dv-left-right-division-content-row align-items-start">

                                <div className="col-md-6">
                                    <div className="dv-right-image">
                                        <img src="./images/business-division/odm.png" alt="" className='img-fluid' />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="dv-left-content">
                                        <p>We offer diverse product designs, rigorous validation, and field testing, ensuring top-notch technology and trusted products. Our ODM division provides specialized service plans, on-site support, and comprehensive training. Additionally, we excel in developing Original Designs to optimize resource utilization.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="business-division-content-heading-dv">
                                <h5>Channel Sales</h5>
                            </div>
                        </div>
                        <div className="col-md-10 offset-md-1">
                            <div className="row dv-left-right-division-content-row align-items-start">
                                <div className="col-md-6">
                                    <div className="dv-left-content">
                                        <p>We aim to collaborate with patrons and customers in their exploration journey, focusing on delivering an exceptional product experience for an emerging brand. Our extensive network of over 2000 channel partners, including dealers and distributors, serves as our valued extended team in achieving this objective.</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="dv-right-image">
                                        <img src="./images/business-division/channel-sales.png" alt="" className='img-fluid' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BussinessDivisionContent