import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import BussinessDivisionsBanner from './BussinessDivisionsBanner/BussinessDivisionsBanner'
import BussinessDivisionContent from './BussinessDivisionContent/BussinessDivisionContent'

const BussinessDivisionsPage = () => {
    return (
        <>
            <Navbar/>
            <NavbarSecond />
            <MobileNavbar/>
            <BussinessDivisionsBanner/>
            <BussinessDivisionContent/>
            <Footer/>
        </>
    )
}

export default BussinessDivisionsPage