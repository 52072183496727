import React from 'react'
import AboutBannerCompo from './AboutBannerCompo/AboutBannerCompo'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import OurJourneyComponent from './OurJourneyComponent/OurJourneyComponent'
import AboutUsContentDark from './AboutUsContentDark/AboutUsContentDark'
import VisionMissionSec from './VisionMissionSec/VisionMissionSec'
import FounderMDSection from './FounderMDSection/FounderMDSection'
import AboutOurSpecialization from './AboutOurSpecialization/AboutOurSpecialization'
import AchievementsComponent from './AchievementsComponent/AchievementsComponent'

const AboutUs = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <AboutBannerCompo />
            <OurJourneyComponent/>
            <AboutUsContentDark/>
            <VisionMissionSec/>
            <FounderMDSection/>
            <AboutOurSpecialization/>
            <AchievementsComponent/>
            <Footer/>
        </>
    )
}

export default AboutUs