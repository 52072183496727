import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
import "./LifeAteaproSlider.css";
import "./ResponsiveLifeAteaproSlider.css";
import $ from 'jquery';
const JavascriptVoid = (event) => {
    event.preventDefault();
};
const Slider_group = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    lazyLoad: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    initialSlide: 0,
    arrows: true,
    swipe: true,
    centerMode: true,
    centerPadding: '10px',
    swipeToSlide: true,
    fade: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 0
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
const Slider_group_small = {
    nav: false,
    infinite: true,
    speed: 500,
    lazyLoad: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    initialSlide: 0,
    arrows: false,
    swipe: true,
    centerPadding: '10px',
    swipeToSlide: true,
    dots: true,
    centerMode: false,
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 0
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
const LifeAteaproSlider = () => {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);

    useEffect(() => {
        setNav1(sliderRef1);
        setNav2(sliderRef2);
    }, []);
    return (
        <>
            <section className='life-at-eapro-slider-section'>
                <div className="container">
                    <div className="heading-dv">
                        <h5>Life@ EAPRO</h5>
                    </div>
                    <div className="heading-tabs-parent">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#technical-video" type="button" role="tab" aria-controls="home" aria-selected="true">Workplace</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link blogs-btn" id="profile-tab" data-bs-toggle="tab" data-bs-target="#blogs-tabs" type="button" role="tab" aria-controls="profile" aria-selected="false">Fun@Work</button>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="technical-video" role="tabpanel" aria-labelledby="home-tab">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 offset-md-2 offset-lg-2 col-12">
                                        <Slider asNavFor={nav2} ref={slider => (sliderRef1 = slider)} {...Slider_group} className='slider-parent-dv'>
                                        <div className='slider-item-dv'>
                                                <div className="slider-item-img">
                                                    <img src="../images/life-at-eapro/img-1.png" alt="" className='img-fluid' />
                                                </div>
                                            </div>
                                            <div className='slider-item-dv'>
                                                <div className="slider-item-img">
                                                    <img src="../images/life-at-eapro/img-2.png" alt="" className='img-fluid' />
                                                </div>
                                            </div>
                                            <div className='slider-item-dv'>
                                                <div className="slider-item-img">
                                                    <img src="../images/life-at-eapro/img-3.png" alt="" className='img-fluid' />
                                                </div>
                                            </div>
                                            <div className='slider-item-dv'>
                                                <div className="slider-item-img">
                                                    <img src="../images/life-at-eapro/img-4.png" alt="" className='img-fluid' />
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-12">
                                        <Slider asNavFor={nav1}
                                            ref={slider => (sliderRef2 = slider)}
                                            slidesToShow={3}
                                            swipeToSlide={true}
                                            focusOnSelect={true} {...Slider_group_small} className='slider-parent-dv1'>
                                            <div className='slider-item-dv'>
                                                <div className="slider-item-img">
                                                    <img src="../images/life-at-eapro/img-1.png" alt="" className='img-fluid' />
                                                </div>
                                            </div>
                                            <div className='slider-item-dv'>
                                                <div className="slider-item-img">
                                                    <img src="../images/life-at-eapro/img-2.png" alt="" className='img-fluid' />
                                                </div>
                                            </div>
                                            <div className='slider-item-dv'>
                                                <div className="slider-item-img">
                                                    <img src="../images/life-at-eapro/img-3.png" alt="" className='img-fluid' />
                                                </div>
                                            </div>
                                            <div className='slider-item-dv'>
                                                <div className="slider-item-img">
                                                    <img src="../images/life-at-eapro/img-4.png" alt="" className='img-fluid' />
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                                {/* <div className="footer-view-all-btn">
                                    <a href="#">View All</a>
                                </div> */}
                            </div>
                            <div class="tab-pane fade" id="blogs-tabs" role="tabpanel" aria-labelledby="profile-tab">
                                ...........
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default LifeAteaproSlider