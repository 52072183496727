import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import EventPageDetailsBanner from './EventPageDetailsBanner/EventPageDetailsBanner'
import EventPageDetailsContent from './EventPageDetailsContent/EventPageDetailsContent'

const EventPageDetails = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <EventPageDetailsBanner/>
            <EventPageDetailsContent/>
            <Footer />
        </>
    )
}

export default EventPageDetails