import React from 'react'
import "./OurJourneyComponent.css"
const OurJourneyComponent = () => {
    return (
        <>
            <section className='our-journey-component-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-xl-12">
                            <div className="our-journey-heading-dv">
                                <h5>Our Journey</h5>
                            </div>
                        </div>
                        <div className="col-md-8 offset-md-2 offset-lg-2 offset-xl-2 col-lg-8 col-xl-8">
                            <div className="journey-images-parent">
                                <img src="./images/about-us/journey-img.png" alt="" className='img-fluid'/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurJourneyComponent