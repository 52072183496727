import React from 'react'
import "./OurProductSection.css"
import "./ResponsiveOurProductSection.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
const Slider_group = {
    dots: false,
    nav: false,
    infinite: true,
    speed: 500,
    lazyLoad: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 0
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
const OurProductSection = () => {
    return (
        <>
            <section className='our-product-section'>
                <div className="container">
                    <div className="heading-dv">
                        <h5>Our Products</h5>
                    </div>
                    <div className="row">
                        <div className="col-md-10 col-lg-10 col-12 offset-md-1 offset-lg-1">
                            <Slider {...Slider_group} className=" product-card-row">
                                {/* <div className="col-md-1"></div> */}
                                <div className=" product-card-items">
                                    <a href="#">
                                        <div className="product-card-right">
                                            <div className="product-heading">
                                                <h6>Power Solutions</h6>
                                            </div>
                                            <div className="product-image">
                                                <img src="../images/our-product-1.png" alt="" className='img-fluid' />
                                            </div>
                                            <div className="product-details-content">
                                                <p>Make your days hassle-free. EAPRO is a leading brand when it comes to delivering first-rate power solutions in India.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className=" product-card-items">
                                    <a href="#">
                                        <div className="product-card-right">
                                            <div className="product-heading">
                                                <h6>Solar Solutions</h6>
                                            </div>
                                            <div className="product-image">
                                                <img src="../images/our-product-2.png" alt="" className='img-fluid' />
                                            </div>
                                            <div className="product-details-content">
                                                <p>Upgrade to the World of Solar Installing a solar panel system on rooftops can be ideal for harnessing the sun’s energy.</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                {/* <div className="col-md-1"></div> */}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurProductSection