import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import GlobalFootprintsBanner from './GlobalFootprintsBanner/GlobalFootprintsBanner'
import AboutExpert from './AboutExpert/AboutExpert'
import ExportEnquiryForm from './ExpertEnquiryForm/ExportEnquiryForm'
const GlobalFootprintsPage = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <GlobalFootprintsBanner/>
            <AboutExpert/>
            <ExportEnquiryForm/>
            <Footer />
        </>
    )
}

export default GlobalFootprintsPage