import React from 'react'
import CareerAtEaproOneBanner from './CareerAtEaproOneBanner/CareerAtEaproOneBanner'
import Navbar from '../../Header/Navbar'
import NavbarSecond from '../../Header/NavbarSecond'
import MobileNavbar from '../../Header/MobileNavbar'
import Footer from '../../Footer/Footer'
import CareerAtEaproOneContent from './CareerAtEaproOneContent/CareerAtEaproOneContent'
const CareerAtEaproOne = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <CareerAtEaproOneBanner />
            <CareerAtEaproOneContent/>
            <Footer />
        </>
    )
}

export default CareerAtEaproOne