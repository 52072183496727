import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import StoreLocatorBanner from './StoreLocatorBanner/StoreLocatorBanner'
import StoreLocatorContent from './StoreLocatorContent/StoreLocatorContent'

const StoreLocatorPage = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <StoreLocatorBanner />
            <StoreLocatorContent/>
            <Footer />
        </>
    )
}

export default StoreLocatorPage