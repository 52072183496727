import React from 'react'
import "./SolarSolutionSolarPanel.css"
const SolarSolutionSolarPanel = () => {
    return (
        <>
            <section className='solar-solution-solar-panel-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="specialization-heading-dv">
                                <h5>Solar Panel</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <div className="left-images">
                                <img src="./images/power-solution/battery-img.png" alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="right-content-parent">
                                <p>Facing frequent power cuts? Bring home the best-in-class, environment-friendly UPS & inverters for your appliances, with hassle-free maintenance that you can rely on for all your energy requirements.</p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="img-content-dv">
                                            <img src="./images/power-solution/automatic-stabilizer.png" alt="" className='img-fluid' />
                                            <p>AUTOMATIC STABLIZER TO BOOST MAIN VOLTAGE UP TO 30% </p>
                                        </div>
                                        <div className="img-content-dv mt-4">
                                            <img src="./images/power-solution/power.png" alt="" className='img-fluid' />
                                            <p>EXTRA 15% POWER THROUGH EMERGENCY POWER BOOST</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="img-content-dv">
                                            <img src="./images/power-solution/battery.png" alt="" className='img-fluid' />
                                            <p>BATTERY CHARGIN AT LOW MAIN FOR LONGER BACKUP & BATTERY LIFE</p>
                                        </div>
                                        <div className="img-content-dv mt-4">
                                            <img src="./images/power-solution/pure-sine.png" alt="" className='img-fluid' />
                                            <p>PURE SINE WAVE TECHNOLOGY</p>
                                        </div>
                                    </div>
                                    <div className="col-md-12 text-center">
                                        <div className="view-product-learn-more">
                                            <a href="#" className='view-product-btn'>View Product</a>
                                            <a href="#" className='view-product-btn'>Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SolarSolutionSolarPanel