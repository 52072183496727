import React from 'react'
import './Banner.css';
import './ResponsiveBanner.css';

const Banner = () => {
    return (
        <>
            <section className="banner-section">
                <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel" >
                    <div className="carousel-indicators">
                        <input
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide-to={0}
                            className="active"
                            aria-current="true"
                            aria-label="Slide 1"
                        />
                        <input
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide-to={1}
                            aria-label="Slide 2"
                        />
                        <input
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide-to={2}
                            aria-label="Slide 3"
                        />
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src="../images/banner1.png" className="d-none d-md-block d-lg-block d-xl-block img-fluid w-100" alt="EAPRO Banner 1" />
                            <img src="../images/mob-banner1.jpg" className="d-block d-md-none d-lg-none d-xl-none img-fluid w-100" alt="EAPRO Banner 1" />

                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="carousel-width">
                                        <h5 className='banner-heading'>Leader of Technology, Maestro of Innovation</h5>
                                        <p className='para-name'>India has emerged as a global leader in the Power Electronics Industry the groundbreaking innovation of the DSP Sine Wave in 2002 by <span>Mr.Jagdeep Chauhan</span>, the Founder of EAPRO</p>
                                        <div className='learn-more-btn'>
                                            <a href="#">
                                                <i class="fa fa-angle-double-right" aria-hidden="true"></i> Learn More
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="carousel-item">
                            <img src="../images/banner2.png" className="d-none d-md-block d-lg-block d-xl-block img-fluid w-100" alt="EAPRO Banner 1" />
                            <img src="../images/mob-banner.jpg" className="d-block d-md-none d-lg-none d-xl-none img-fluid w-100" alt="EAPRO Banner 1" />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="carousel-width">
                                        <h5 className='banner-heading'>Leader of Technology, Maestro of Innovation</h5>
                                        <p className='para-name'>India has emerged as a global leader in the Power Electronics Industry the groundbreaking innovation of the DSP Sine Wave in 2002 by <span>Mr.Jagdeep Chauhan</span>, the Founder of EAPRO</p>
                                        <div className='learn-more-btn'>
                                            <a href="#">
                                                <i class="fa fa-angle-double-right" aria-hidden="true"></i> Learn More
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src="../images/banner3.png" className="d-none d-md-block d-lg-block d-xl-block img-fluid w-100" alt="EAPRO Banner 1" />
                            <img src="../images/mob-banner.jpg" className="d-block d-md-none d-lg-none d-xl-none img-fluid w-100" alt="EAPRO Banner 1" />
                            <div className="carousel-caption">
                                <div className="container">
                                    <div className="carousel-width">
                                        <h5 className='banner-heading'>Leader of Technology, Maestro of Innovation</h5>
                                        <p className='para-name'>India has emerged as a global leader in the Power Electronics Industry the groundbreaking innovation of the DSP Sine Wave in 2002 by <span>Mr.Jagdeep Chauhan</span>, the Founder of EAPRO</p>
                                        <div className='learn-more-btn'>
                                            <a href="#">
                                                <i class="fa fa-angle-double-right" aria-hidden="true"></i> Learn More
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="prev"
                    >
                        <span className="carousel-control-prev-icon" aria-hidden="true" />
                        <span className="visually-hidden">Previous</span>
                    </button> */}
                    {/* <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="next"
                    >
                        <span className="carousel-control-next-icon" aria-hidden="true" />
                        <span className="visually-hidden">Next</span>
                    </button> */}
                </div>
            </section>

        </>
    )
}

export default Banner