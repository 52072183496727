import React from 'react'
import "./RegisterYourComplaintBanner.css"
import "./ResponsiveRegisterYourComplaintBanner.css"
const RegisterYourComplaintBanner = () => {
    return (
        <>
            {/* <section className='register-your-complaint-banner-section'>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 text-start">
                            <div className='business-parent-dv'>
                                <div className="business-heading text-start">
                                    <img src="./images/help-and-support/heading-bg.png" alt="" />
                                    <h1>Register Your Complaint</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className='register-your-complaint-banner-section'>
                <div className=" align-items-center">
                    <div className="text-start">
                        <div className="banner-top-img-bg-rel">
                            <img src="./images/help-and-support/banner-bg.jpg" alt="" className='img-fluid banner-img-tag' />
                            <div className="banner-top-content-absol business-heading text-start">
                                <img src="./images/help-and-support/heading-bg.png" alt="" className='img-fluid' />
                                <div className='business-banner-heading'>
                                    <div className="container">
                                        <h1>Register Your Complaint</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RegisterYourComplaintBanner