import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import KnowledgeCentrePageBanner from './KnowledgeCentrePageBanner/KnowledgeCentrePageBanner'
import KnowledgeCentrePageContent from './KnowledgeCentrePageContent/KnowledgeCentrePageContent'
import KnowledgeCentrePageVideoTuto from './KnowledgeCentrePageContent/KnowledgeCentrePageVideoTuto'
import KnowledgeCentrePageNewsElement from './KnowledgeCentrePageContent/KnowledgeCentrePageNewsElement'
import KnowledgeCenterPageBlogs from './KnowledgeCentrePageContent/KnowledgeCenterPageBlogs'

const KnowledgeCentrePage = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <KnowledgeCentrePageBanner />
            <KnowledgeCentrePageNewsElement/>
            <KnowledgeCentrePageVideoTuto/>
            <KnowledgeCenterPageBlogs/>
            <Footer />
        </>
    )
}

export default KnowledgeCentrePage