import React from 'react'
import "./VisionMissionSec.css"
const VisionMissionSec = () => {
    return (
        <>
            <section className='vision-mission-value-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 offset-md-1 offset-lg-1 col-lg-10">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="vision-mission-value-dv">
                                        <div className="v-m-v-img">
                                            <img src="./images/about-us/mission-icon.png" alt="" className='img-fluid' />
                                        </div>
                                        <div className="v-m-v-heading">
                                            <h4>Our <span>Mission</span></h4>
                                        </div>
                                        <div className="v-m-v-content">
                                            <p>India's premier Solar Solution Provider, offering innovative and sustainable energy solutions, ranked a Top 10 Employer, empowering and inspiring, while ensuring unparalleled Customer Satisfaction in Solar Segment.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="vision-mission-value-dv">
                                        <div className="v-m-v-img">
                                            <img src="./images/about-us/vision-icon.png" alt="" className='img-fluid' />
                                        </div>
                                        <div className="v-m-v-heading">
                                            <h4>Our <span>Vision</span></h4>
                                        </div>
                                        <div className="v-m-v-content">
                                            <p>World's leading solar inverter brand, revolutionizing solar solutions through relentless research and innovation to benefit society with cutting-edge technology.</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-4">
                                    <div className="vision-mission-value-dv">
                                        <div className="v-m-v-img">
                                            <img src="./images/about-us/value-icon.png" alt="" className='img-fluid' />
                                        </div>
                                        <div className="v-m-v-heading">
                                            <h4>Our <span>Values</span></h4>
                                        </div>
                                        <div className="v-m-v-content">
                                            <p>Integrity in EAPRO is about embodying honesty, a resolute moral sense, and embracing fallibility. It's not just about being right, but striving to do right and learning from mistakes.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default VisionMissionSec