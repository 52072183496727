import React, { useEffect } from 'react'
import "./CareerAtEaproThreeContent.css"
import { Link } from 'react-router-dom'
const CareerAtEaproThreeContent = () => {
  return (
    <>
      <section className='career-at-eapro-three-con-search-jobs'>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <form action="">
                <div className="row">
                  <div className="col-md-6 text-start">
                    <label htmlFor="search-by-keyword-id" className='form-label'>Search by Keyword</label>
                    <input type="text" className="form-control" placeholder='' id='search-by-keyword-id' />
                  </div>
                  <div className="col-md-6 text-start">
                    <label htmlFor="search-by-location-id" className='form-label'>Search by Location</label>
                    <input type="text" className="form-control" placeholder='' id='search-by-location-id' />
                  </div>
                  <div className="col-md-12">
                    <div className="search-job-btn">
                      <a href="" className=''>Search Jobs</a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section className='career-at-eapro-three-page-content-section'>
        <div className="container">
          <div className="listing-parent-dv text-start">
            <div className="pagination-dv">
              <p>
                <b>Results : 1 - 10 of 15</b>
              </p>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-chevron-left"
                        viewBox="0 0 16 16"
                      >
                        <path stroke="black"
                          fillRule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                        />
                      </svg>
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item active" aria-current="page">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path stroke="black"
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr className="table-dark">
                    <th scope="col">Job Title</th>
                    <th scope="col">Job Location</th>
                    <th scope="col">Date of Posting Job</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                      <td><Link to="/career-at-eapro-details">Assistant Manager Corporate Sales</Link></td>
                      <td><Link to="/career-at-eapro-details">New Delhi, North, India</Link></td>
                      <td>Jan 13, 2024</td>
                  </tr>
                  <tr>
                    <td><Link to="/career-at-eapro-details">Assistant Manager Corporate Sales / Mangalore</Link></td>
                    <td><Link to="/career-at-eapro-details">Riyadh, Saudi Arabia, India</Link></td>
                    <td>Jan 12, 2024</td>
                  </tr>
                  <tr>
                      <td><Link to="/career-at-eapro-details">Assistant Manager Corporate Sales</Link></td>
                      <td><Link to="/career-at-eapro-details">New Delhi, North, India</Link></td>
                      <td>Jan 13, 2024</td>
                  </tr>
                  <tr>
                    <td><Link to="/career-at-eapro-details">Assistant Manager Corporate Sales / Mangalore</Link></td>
                    <td><Link to="/career-at-eapro-details">Riyadh, Saudi Arabia, India</Link></td>
                    <td>Jan 12, 2024</td>
                  </tr>
                  <tr>
                      <td><Link to="/career-at-eapro-details">Assistant Manager Corporate Sales</Link></td>
                      <td><Link to="/career-at-eapro-details">New Delhi, North, India</Link></td>
                      <td>Jan 13, 2024</td>
                  </tr>
                  <tr>
                    <td><Link to="/career-at-eapro-details">Assistant Manager Corporate Sales / Mangalore</Link></td>
                    <td><Link to="/career-at-eapro-details">Riyadh, Saudi Arabia, India</Link></td>
                    <td>Jan 12, 2024</td>
                  </tr>
                  <tr>
                      <td><Link to="/career-at-eapro-details">Assistant Manager Corporate Sales</Link></td>
                      <td><Link to="/career-at-eapro-details">New Delhi, North, India</Link></td>
                      <td>Jan 13, 2024</td>
                  </tr>
                  <tr>
                    <td><Link to="/career-at-eapro-details">Assistant Manager Corporate Sales / Mangalore</Link></td>
                    <td><Link to="/career-at-eapro-details">Riyadh, Saudi Arabia, India</Link></td>
                    <td>Jan 12, 2024</td>
                  </tr>
                  <tr>
                      <td><Link to="/career-at-eapro-details">Assistant Manager Corporate Sales</Link></td>
                      <td><Link to="/career-at-eapro-details">New Delhi, North, India</Link></td>
                      <td>Jan 13, 2024</td>
                  </tr>
                  <tr>
                    <td><Link to="/career-at-eapro-details">Assistant Manager Corporate Sales / Mangalore</Link></td>
                    <td><Link to="/career-at-eapro-details">Riyadh, Saudi Arabia, India</Link></td>
                    <td>Jan 12, 2024</td>
                  </tr>
                  <tr>
                      <td><Link to="/career-at-eapro-details">Assistant Manager Corporate Sales</Link></td>
                      <td><Link to="/career-at-eapro-details">New Delhi, North, India</Link></td>
                      <td>Jan 13, 2024</td>
                  </tr>
                  <tr>
                    <td><Link to="/career-at-eapro-details">Assistant Manager Corporate Sales / Mangalore</Link></td>
                    <td><Link to="/career-at-eapro-details">Riyadh, Saudi Arabia, India</Link></td>
                    <td>Jan 12, 2024</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="pagination-dv">
              <p>
                <b>Results : 1 - 10 of 15</b>
              </p>
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-chevron-left"
                        viewBox="0 0 16 16"
                      >
                        <path stroke="black"
                          fillRule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
                        />
                      </svg>
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item active" aria-current="page">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        fill="currentColor"
                        className="bi bi-chevron-right"
                        viewBox="0 0 16 16"
                      >
                        <path stroke="black"
                          fillRule="evenodd"
                          d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
                        />
                      </svg>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="upload-btn-para-bottom">
              <p>Ready to take the next step in your career?</p>
              <p className='unable-to-find'>Unable to find the Job</p>
              <div className='upload-your-dv'>
                <Link to="/career-at-eapro-form">Upload Your CV</Link>
              </div>
            </div>

          </div>


        </div>
      </section>
    </>
  )
}

export default CareerAtEaproThreeContent