import React from 'react'
import PowerSolutionPageBanner from './PowerSolutionPageBanner/PowerSolutionPageBanner'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import PowerSolutionPageDetails from './PowerSolutionPageDetails/PowerSolutionPageDetails'
import PowerSolutionHomeInverter from './PowerSolutionHomeInverter/PowerSolutionHomeInverter'
import PowerSolutionBatteryPage from './PowerSolutionBatteryPage/PowerSolutionBatteryPage'
import ConverterAndControllerPage from './ConverterAndControllerPage/ConverterAndControllerPage'
import PowerSolutionPageAccessories from './PowerSolutionPageAccessories/PowerSolutionPageAccessories'

const PowerSolutionPage = () => {
  return (
    <>
        <Navbar/>
        <NavbarSecond/>
        <MobileNavbar/>
        <PowerSolutionPageBanner/>
        <PowerSolutionPageDetails/>
        <PowerSolutionHomeInverter/>
        <PowerSolutionBatteryPage/>
        <ConverterAndControllerPage/>
        <PowerSolutionPageAccessories/>
        <Footer/>
    </>
  )
}

export default PowerSolutionPage