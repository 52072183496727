import React from 'react'
import "./ECataloguePageContent.css"
const ECatalogueCard = () => {
    return (
        <>
            <div className="catalogue-card-parent-dv">
                <div className="c-card-img">
                    <img src="./images/e-catalogue/card-img.png" alt="" className='img-fluid'/>
                </div>
                <div className="first-content-strip">
                    <p><a href="#">Non – Solar catalogue </a></p>
                </div>
                <div className="second-content-strip">
                    <p><a href="#">Download</a></p>
                </div>
            </div>
        </>
    )
}

export default ECatalogueCard