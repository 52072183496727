import React from 'react'
import CountUp from 'react-countup';
import "./WhyEapro.css"
import "./ResponsiveWhyEapro.css"
const WhyEapro = () => {
  return (
    <>
    <section className='why-eapro-section'>
        <div className="container align-items-center">
            <div className="row">
                <div className="col-md-12">
                    <div className="why-heading-dv">
                        <h5>Why EAPRO ?</h5>
                    </div>
                </div>
                <div className="col-md-10 col-lg-10 col-12 offset-md-1 offset-lg-1">
                    <div className="para-content">
                        <p>Eapro Products are known for their high reliability and performance. They are built with quality components and undergo stringent quality control measures to ensure consistent and dependable operation. With an Eapro Products, you can have peace of mind knowing that your power backup needs will be efficiently handled.</p>
                    </div>
                </div>
                <div className="col-md-10 col-lg-10 col-12 offset-md-1 offset-lg-1">
                    <div className="row counter-row">
                        <div className="col-md-4">
                            <div className="card-box-counter">
                                <h4><CountUp duration={5.00} end={11} /> M+</h4>
                               
                                <p>Customers</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="card-box-counter bg-yellow">
                                <h4><CountUp duration={5.00} end={100} /> +</h4>
                                <p>Products</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="card-box-counter">
                                <h4><CountUp duration={5.00} end={15000} /> +</h4>
                                <p>Dealers Network </p>
                            </div>
                        </div>
                        
                    </div>
                </div>             
            </div>
        </div>
    </section>
    </>
  )
}

export default WhyEapro