import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import EaproGlobalEnergyBanner from './EaproGlobalEnergyBanner/EaproGlobalEnergyBanner'
import EaproGlobalEnergyContent from './EaproGlobalEnergyContent/EaproGlobalEnergyContent'
import EaproGlobalEnergyForms from './EaproGlobalEnergyContent/EaproGlobalEnergyForms'

const EaproGlobalEnergy = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <EaproGlobalEnergyBanner />
            <EaproGlobalEnergyContent />
            <EaproGlobalEnergyForms/>
            <Footer />
        </>
    )
}

export default EaproGlobalEnergy