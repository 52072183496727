import React from 'react'
import "./EaproGlobalEnergyBanner.css"
import "./ResponsiveEaproGlobalEnergyBanner.css"
const EaproGlobalEnergyBanner = () => {
    return (
        <>
            <section className='global-energy-banner-section'>
                {/* <div className="container-fluid"> */}
                {/* <div className="row align-items-center"> */}
                <div className="col-md-12 text-start">
                    <div className="business-heading text-start">
                        <img src="./images/global-energy/enerygy-banner.png" alt="" className='img-fluid' />
                        {/* <h1>Help & Support</h1> */}
                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
            </section>
        </>
    )
}

export default EaproGlobalEnergyBanner