import React from 'react'
import "./EaproGlobalLtdBanner.css"
import "./ResponsiveEaproGlobalLtdBanner.css"
const EaproGlobalLtdBanner = () => {
    return (
        <>
            <section className='global-energy-ltd-banner-section'>
                {/* <div className="container-fluid"> */}
                    {/* <div className="row align-items-center"> */}
                        <div className="col-md-12 text-start">
                            <div className="business-heading text-start">
                                <img src="./images/global-energy/banner-global-ltd.jpg" alt="" className='img-fluid'/>
                                {/* <h1>Help & Support</h1> */}
                            </div>
                        </div>
                    {/* </div> */}
                {/* </div> */}
            </section>
        </>
    )
}

export default EaproGlobalLtdBanner