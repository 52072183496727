import React from 'react'
import "./ContactUsPageContent.css"
import { Link } from 'react-router-dom'
const ContactUsPageContent = () => {
  return (
    <>
      <section className='contact-us-content-section'>
        <div className="contact-parent-card">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7">
                <div className="left-contact-card">
                  <div className="image-heading">
                    <img src="../images/contact-us/location-pin.png" alt="" />
                    <h3>Our Address</h3>
                  </div>
                  <div className="corporate-address-parent">
                    <div className="corporate-office-address">
                      <h3>Corporate Office</h3>
                      <p>Upper Ganga canal Besides, Asaf Nagar, Delhi Rd, Roorkee, Uttarakhand 247666</p>
                    </div>
                    <div className="get-direction-btn">
                      <button className="btn submit-btn-custom" type="submit">
                        Get Location
                      </button>
                    </div>
                  </div>
                  <div className="industries-address-parent">
                    <div className="corporate-office-address">
                      <h3>Industries Address</h3>
                      <p>Plot No. 106-107,Salempur Rajputana Industrial Area, Roorkee - 247667,Distt. Haridwar (U.K.), INDIA</p>
                    </div>
                    <div className="get-direction-btn">
                      <button className="btn submit-btn-custom" type="submit">
                        Get Location
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="right-contact-card">
                  <div className="image-heading">
                    <img src="../images/contact-us/phone.png" alt="" />
                    <h3>Call Us</h3>
                    <a href="tel:+91-8650115000">8650115000</a>
                  </div>
                </div>
                <div className="right-contact-card mt-4">
                  <div className="image-heading1">
                    <img src="../images/contact-us/email.png" alt="" />
                    <h3>Email Us</h3>
                    <a href="mailto:info@eapro.in">info@eapro.in</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="help-support-heading">
                <h5>Customer Support</h5>
              </div>
            </div>
          </div>
          <div className="form-parent-custon-dv">
            <form className="row g-3 custom-row-form form-container">

              <div className="col-md-12">
                <label htmlFor="full-name" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="full-name"
                  defaultValue=""
                  required=""
                  placeholder='Full Name'
                />
              </div>
              <div className="col-md-12">
                <label htmlFor="contact-details" className="form-label">
                  Contact Details
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="contact-details"
                  defaultValue=""
                  required=""
                  placeholder='Mobile Number'
                />
              </div>
              <div className="col-md-12">
                <label htmlFor="email-id" className="form-label">
                  Email ID
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email-id"
                  defaultValue=""
                  required=""
                  placeholder='Email'
                />
              </div>

              <div className="col-md-12">
                <label htmlFor="complaint-details" className="form-label">
                  Complaint in Detail
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="complaint-details"
                  defaultValue=""
                  required=""
                  placeholder='Type Your Message Here'
                ></textarea>
              </div>
              <div className="col-12 text-center">
                <button className="btn submit-btn-custom" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactUsPageContent