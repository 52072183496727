import React from 'react'
import './Navbar.css';
import './ResponsiveNavbar.css';
import $ from 'jquery';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import 'font-awesome/css/font-awesome.min.css';
import { Link } from 'react-router-dom';
const NavbarSecond = () => {
    return (
        <>
            <section className='navbar-second-section d-none d-md-none d-lg-block'>
                <nav class="navbar navbar-expand-lg navbar-light bg-white">
                    <div class="container">
                        <a class="navbar-brand" href="#" style={{width:"175px"}}></a>
                        <button class="navbar-toggler d-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNav">
                            <ul class="navbar-nav ms-auto">
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        About Us <i class="fa fa-angle-down" aria-hidden="true"></i>
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <li><Link class="dropdown-item" to="/about-us">EAPRO Group</Link></li>
                                        <li><Link class="dropdown-item" to="/global-energy-ltd">EAPRO Global Ltd.</Link></li>
                                        <li><Link class="dropdown-item" to="/global-energy">EAPRO Global Energy Pvt. Ltd.</Link></li>
                                        <li><Link class="dropdown-item" to="/eapro-overseas">EAPRO Overseas Pvt. Ltd.</Link></li>
                                        <li><Link class="dropdown-item" to="/trc-efficient-services">TRC Efficient & Services Pvt. Ltd.</Link></li>
                                        <li><Link class="dropdown-item" to="/efficient-mechanical-solar">Efficient Mechanical & Solar Pvt. Ltd.</Link></li>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" to="/business-division">Business Divisions</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" to="/help-and-support">Support</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" to="/career-at-eapro">Career</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" to="/knowledge-center">Knowledge Center</Link>
                                </li>
                                {/* <li class="nav-item search-item my-auto">
                                    <div className="search-inner-dv">
                                        <input type="text" placeholder='Search for a product' />
                                        <a href="#" className='search-icon-a-tag'><i class="fa fa-search" aria-hidden="true"></i></a>
                                    </div>
                                </li> */}

                            </ul>
                            <ul className="navbar-nav navbar-nav-second-ul">
                                <li class="nav-item search-item my-auto">
                                    <div className="search-inner-dv">
                                        <input type="text" placeholder='Search for a product' />
                                        <a href="#" className='search-icon-a-tag'><i class="fa fa-search" aria-hidden="true"></i></a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </section>
        </>
    )
}

export default NavbarSecond