import React from 'react'
import "./AchievementsComponent.css"
const AchievementsComponent = () => {
    return (
        <>
            <div className="achievements-component-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="achievements-component-heading-dv">
                                <h5>Our Achievements</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row my-4 align-items-center">
                        <div className="col-md-6">
                            <div className="achievements-award-img-left">
                                <img src="./images/about-us/our-achievement-left.png" alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="achievement-award-right-dv-content">
                                <p>Recognizing and celebrating excellence, dedication, and outstanding contributions that inspire us all.</p>
                                <ul>
                                    <li>Start -up to Unicorn Accolade in Solar Power Innovation by Zee Media in New Delhi (Nov 2021) - <b>Honorable Cabinet Minister Sh. Piyush Goyal Ji</b></li>
                                    <li>Uttarakhand Contributor Accolade for Solar Power Innovation and Employment in Uttarakhand (Dec 2021) - <b>Honorable CM Sh. Pushkar Singh Dham Ji</b></li>
                                    <li>Asia Pacific Most Promising Entrepreneurship Award in 2010 - APEA, Singapore</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row my-4 align-items-center">
                        <div className="col-md-6">
                            <div className="achievement-award-right-dv-content">
                                <p>Honoring exceptional achievements and remarkable dedication that serve as an inspiration to all.</p>
                                <ul>
                                    <li>Outstanding Contribution award in Power Electronics at MSME, Jaipur's 'Global Business Opportunities & Latest Trends in Solar Energy' (Oct 2018).</li>
                                    <li>Best Company Award in Innovation of ON GRID Hybrid Inverter at Sigma Summit (International Solar Alliance)- Jan 2019, New Delhi.</li>
                                    <li>Silicon India 10 Most Promising Original Design Manufacturing, 2019 award for high quality, efficient products.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="achievements-award-img-left">
                                <img src="./images/about-us/our-achievement-right.png" alt="" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AchievementsComponent