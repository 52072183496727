import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import HelpAndSupportBanner from './HelpAndSupportBanner/HelpAndSupportBanner'
import HelpAndSupportContent from './HelpAndSupportContent/HelpAndSupportContent'

const HelpAndSupportPage = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <HelpAndSupportBanner/>
            <HelpAndSupportContent/>
            <Footer />
        </>
    )
}

export default HelpAndSupportPage