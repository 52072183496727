import React from 'react'
import Navbar from '../Header/Navbar'
import NavbarSecond from '../Header/NavbarSecond'
import MobileNavbar from '../Header/MobileNavbar'
import Footer from '../Footer/Footer'
import CareerPageBanner from './CareerPageBanner/CareerPageBanner'
import CareerPageContent from './CareerPageContent/CareerPageContent'

const CareerPage = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <CareerPageBanner />
            <CareerPageContent />
            <Footer/>
        </>
    )
}

export default CareerPage