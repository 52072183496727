import React from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'font-awesome/css/font-awesome.min.css';
import "./KnowledgeCentrePageContent.css"
import $ from 'jquery';
const JavascriptVoid = (event) => {
    event.preventDefault();
};
function pauseVideo() {
    console.log("trying to pause")
    let videoId = document.getElementById("iframeyt");
    videoId.contentWindow.postMessage('{"event":"command", "func":"stopVideo", "args":""}', '*')
}
const Slider_group = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    lazyLoad: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    initialSlide: 0,
    arrows: true,
    swipe: true,
    centerMode: true,
    centerPadding: '10px',
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 0
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
const KnowledgeCenterPageBlogs = () => {
    return (
        <>
            <section className='knowledge-centre-page-bblogs-element-section'>
                <div className="container">
                    <div className="heading-dv">
                        <h5>Blogs</h5>
                    </div>
                    <div className="heading-tabs-parent">
                        <Slider {...Slider_group} className='slider-parent-dv'>
                            <div className='slider-item-dv'>
                                <div className="slider-item-img">
                                    <img src="../images/knowledge-center/1.png" alt="" className='img-fluid' />
                                    {/* <div className="play-btn-youtube">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                            <img src="../images/youtube-play-btn.png" alt="" className='img-fluid ' />
                                        </a>
                                    </div> */}
                                </div>
                                <div className="slider-item-heading">
                                    <h5>Lorem Ipsum</h5>
                                    <p>Yoga is an ancient practice that originated in India and encompasses physical, mental, and spiritual aspects.</p>
                                    <p className='mt-2'><b>June 21, 2023</b></p>
                                </div>
                            </div>
                            <div className='slider-item-dv'>
                                <div className="slider-item-img">
                                    <img src="../images/knowledge-center/2.png" alt="" className='img-fluid' />
                                    {/* <div className="play-btn-youtube">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                            <img src="../images/youtube-play-btn.png" alt="" className='img-fluid ' />
                                        </a>
                                    </div> */}
                                </div>
                                <div className="slider-item-heading">
                                    <h5>Lorem Ipsum</h5>
                                    <p>Each year, World Blood Donor Day has a specific theme that focuses on various aspects of blood donation and its significance.</p>
                                    <p className='mt-2'><b>Jun 14, 2023</b></p>
                                </div>
                            </div>
                            <div className='slider-item-dv'>
                                <div className="slider-item-img">
                                    <img src="../images/knowledge-center/3.png" alt="" className='img-fluid' />
                                    {/* <div className="play-btn-youtube">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                            <img src="../images/youtube-play-btn.png" alt="" className='img-fluid ' />
                                        </a>
                                    </div> */}
                                </div>
                                <div className="slider-item-heading">
                                    <h5>Lorem Ipsum</h5>
                                    <p>Yoga is an ancient practice that originated in India and encompasses physical, mental, and spiritual aspects.</p>
                                    <p className='mt-2'><b>May 13, 2023</b></p>
                                </div>
                            </div>
                            <div className='slider-item-dv'>
                                <div className="slider-item-img">
                                    <img src="../images/knowledge-center/1.png" alt="" className='img-fluid' />
                                    {/* <div className="play-btn-youtube">
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                            <img src="../images/youtube-play-btn.png" alt="" className='img-fluid ' />
                                        </a>
                                    </div> */}
                                </div>
                                <div className="slider-item-heading">
                                    <h5>Lorem Ipsum</h5>
                                    <p>Yoga is an ancient practice that originated in India and encompasses physical, mental, and spiritual aspects.</p>
                                    <p className='mt-2'><b>June 21, 2023</b></p>
                                </div>
                            </div>
                        </Slider>
                    </div>

                </div>
            </section>
        </>
    )
}

export default KnowledgeCenterPageBlogs