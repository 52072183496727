import React from 'react'
import "./PurchaseAMCContent.css"
import { Link } from 'react-router-dom'
const PurchaseAMCContent = () => {
    return (
        <>
            <section className='purchase-amc-content-content-section'>
                <div className="container">

                    <div className="form-parent-custon-dv">
                        <form className="row g-3 custom-row-form form-container">

                            <div className="col-md-12">
                                <Link to="/purchase-amc3">
                                    <label htmlFor="upload-product-front" className="form-label upload-product-front">
                                        <span>122849848650122</span>
                                        <label htmlFor="upload-product-front" className="upload-product-icon">
                                            <img src="./images/purchase-amc/right-icon.png" alt="" />
                                        </label>
                                    </label>
                                </Link>
                            </div>


                            <div className="col-12 text-center">
                                <Link to="/purchase-amc3" className="btn submit-btn-custom" type="submit">
                                    Proceed
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='help-and-support-section-inner'>
                    <div className="container">
                        {/* <div className="heading-dv">
                            <h5>Help & Suport</h5>
                        </div> */}
                        <div className="help-support-inner-section">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <div className="first-card-parent">
                                        <div className="mobile-tag">
                                            <p><a href="tel:8650115000"><img src="../images/phone.png" alt="" />8650115000</a></p>
                                            <p><img src="../images/alarm.png" alt="" />09:00 AM to 06:00 PM </p>
                                        </div>
                                    </div>
                                    <div className="first-card-parent">
                                        <div className="mobile-tag">
                                            <p><a href="https://api.whatsapp.com/send?phone=919068563156" target='_blank'><img src="../images/whatsapp.png" alt="" />+91-9068513651</a></p>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6">
                                    {/* <div className="second-side-card-parent">
                                        <div className="mobile-tag">
                                            <p>
                                                <a href="#"><img src="../images/book-complaint.png" alt="" />Book a complaint</a>
                                                <a href="#"><i class="fa fa-chevron-right" aria-hidden="true"></i><i class="fa fa-chevron-right" aria-hidden="true"></i></a>

                                            </p>
                                        </div>
                                    </div> */}
                                    <div className="second-side-card-parent">
                                        <div className="mobile-tag">
                                            <p>
                                                <a href="#"><img src="../images/register-product.png" alt="" />Register your product</a>
                                                <a href="#"><i class="fa fa-chevron-right" aria-hidden="true"></i><i class="fa fa-chevron-right" aria-hidden="true"></i></a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="first-card-parent">
                                        <div className="mobile-tag">
                                            <p><a href="mailto:info@eapro.in"><img src="../images/mail.png" alt="" />info@eapro.in</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PurchaseAMCContent