import React from 'react'
import "./ContactUsPageBanner.css"
const ContactUsPageBanner = () => {
  return (
    <>
        <section className='contact-us-page-banner-section'>
        <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5">
                            <div className="banner-images-left">
                                <img src="./images/contact-us/img.png" alt="" className='img-fluid' />
                            </div>
                        </div>
                        <div className="col-md-7 ">
                        <div className="business-heading text-center">
                                {/* <img src="./images/global-footprints/heading-bg.png" alt="" /> */}
                                <h1>We are here to help you!</h1>
                            </div>
                            <div className="business-heading-top">
                                <h5>
                                For immediate assistance or any inquiries, please reach out to our dedicated support team. Your satisfaction is our priority, and we're here to help address your concerns and provide timely solutions.
                                </h5>
                            </div>
                            
                        </div>
                    </div>
                </div>
        </section>
    </>
  )
}

export default ContactUsPageBanner