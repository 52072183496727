import React from 'react'
import CareerAtEaproThreeBanner from './CareerAtEaproThreeBanner/CareerAtEaproThreeBanner'
import Navbar from '../../Header/Navbar'
import NavbarSecond from '../../Header/NavbarSecond'
import MobileNavbar from '../../Header/MobileNavbar'
import Footer from '../../Footer/Footer'
import CareerAtEaproThreeContent from './CareerAtEaproThreeContent/CareerAtEaproThreeContent'
import WhatEaproTeamMateSaySlider from './WhatEaproTeamMateSaySlider/WhatEaproTeamMateSaySlider'
import LifeAteaproSlider from './LifeAteaproSlider/LifeAteaproSlider'
const CareerAtEaproThree = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <CareerAtEaproThreeBanner />
            <CareerAtEaproThreeContent />
            <WhatEaproTeamMateSaySlider />
            <LifeAteaproSlider />
            <Footer />
        </>
    )
}

export default CareerAtEaproThree