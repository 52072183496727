import React from 'react'
import BlogBanner from './BlogBanner/BlogBanner'
import Navbar from '../Header/Navbar'
import MobileNavbar from '../Header/MobileNavbar'
import NavbarSecond from '../Header/NavbarSecond'
import Footer from '../Footer/Footer'
import BlogContent from './BlogContent/BlogContent'

const Blog = () => {
    return (
        <>
            <Navbar />
            <NavbarSecond />
            <MobileNavbar />
            <BlogBanner />
            <BlogContent/>
            <Footer />
        </>
    )
}

export default Blog