import React from 'react'
import "./ExportEnquiryForm.css"
const ExportEnquiryForm = () => {
    return (
        <>
            <section className='export-enquiry-section'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="enquiry-left-d">
                                <img src="./images/global-footprints/enquiry-img.png" alt="" className='img-fluid' />
                                <p>Please fill out the export query contact form below with your details and specific export request. </p>
                                <h5><b>Thank you</b></h5>
                            </div>
                        </div>
                        {/* <div className="col-md-1"></div> */}
                        <div className="col-md-6">
                            <div className="enquiry-form-right-dv">
                                <div className="enquiry-heading">
                                    <h5>Export Enquiry Form</h5>
                                </div>
                                <form action=''>
                                    <div className="mb-4 pb-2">
                                        <input type="text" className="form-control rounded-0" placeholder='Name*'/>
                                    </div>
                                    <div className="mb-4 pb-2">
                                        <input type="email" className="form-control rounded-0" placeholder='Email*'/>
                                    </div>
                                    <div className="mb-4 pb-2">
                                        <input type="text" className="form-control rounded-0" placeholder='Phone*' />
                                    </div>
                                    <div className="mb-4 pb-2">
                                        <textarea name="" id="" className="form-control rounded-0" rows="5" placeholder='Address'>

                                        </textarea>
                                    </div>
                                    <div className="mb-4 pb-2 text-start">
                                    <button type="submit" className="btn btn-primary enq-submit-btn">Submit</button>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ExportEnquiryForm